import { noOpToExportAMethodWhichCanBeCalledBeforeOtherImportsToEnsureWebpackMaintainsCorrectImportOrderForPolyfill1 } from "./polyfill1";
import { noOpToExportAMethodWhichCanBeCalledBeforeOtherImportsToEnsureWebpackMaintainsCorrectImportOrderForPolyfill2 } from "./polyfill2";

noOpToExportAMethodWhichCanBeCalledBeforeOtherImportsToEnsureWebpackMaintainsCorrectImportOrderForPolyfill1();
noOpToExportAMethodWhichCanBeCalledBeforeOtherImportsToEnsureWebpackMaintainsCorrectImportOrderForPolyfill2();

import { startApp } from "./compat-app";
import { startEnrollmentApp } from "./enroll-app";
import { loadComponentStyles } from "./components/componentLoader";
import {
  hasEnrollmentDeviceInfo,
  hasEnrollmentSecret,
} from "../../full/src/services/enrollment-service";
import { logDebug } from "../../full/src/services/logging";

loadComponentStyles();
const w: any = window;
const query = new URLSearchParams(window.location.search);
if (query.get('remove_stored_mode') === 'true'){
  localStorage.removeItem('wosb-mode');
}
const localStorageModeSetting = localStorage.getItem('wosb-mode');
const forcedLightByQueryString = query.get("force_light") === "true";
const needsToUseWosbLight =
  (w.featuresMissing?.length || 0) > 0 || forcedLightByQueryString || localStorageModeSetting === 'compatibility';
logDebug("Device feature detection results", {
  UserAgent: navigator.userAgent,
  FeaturesDetected: w.featuresDetected,
  FeaturesMissing: w.featuresMissing,
  ForcedLight: forcedLightByQueryString,
  LocalStorageModeSetting: localStorageModeSetting
});
const redirectToFullWosb = () =>
  (window.location.href = "/wosb/" + window.location.search);

if (
  /device_secret/.test(window.location.search) ||
  (hasEnrollmentDeviceInfo() && hasEnrollmentSecret())
) {
  if (needsToUseWosbLight && localStorageModeSetting !== 'full') {
    startApp();
  } else {
    redirectToFullWosb();
  }
} else if (/mode=demo/.test(window.location.search)) {
  redirectToFullWosb();
} else {
  startEnrollmentApp();
}
