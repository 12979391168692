import {
  ApplicationInsights,
  ITelemetryItem,
  PageView,
} from "@microsoft/applicationinsights-web";

const w: any = window;
const appInsights = w?.wosbSettings?.applicationInsightsConnectionString
  ? new ApplicationInsights({
      config: {
        connectionString: w.wosbSettings.applicationInsightsConnectionString,
        enableCorsCorrelation: true,
        autoTrackPageVisitTime: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
      },
    })
  : null;

if (appInsights) {
  appInsights.loadAppInsights();

  appInsights.addTelemetryInitializer((item: ITelemetryItem) => {
    if (item.baseData?.url && /device_secret=/i.test(item.baseData.url)) {
      item.baseData.url = item.baseData.url.replace(
        /device_secret=(?:[^&]+)(?:&|$)/i,
        "device_secret=redacted"
      );
    }
  });

  appInsights.context.device.resolution = `${window.innerWidth}x${window.innerHeight}`;
  appInsights.context.application.ver = w.wosbSettings?.version;
}

export interface CustomProperties {
  [key: string]: any;
}

const contextState: CustomProperties = {};
export const addLoggingContextState = (state: CustomProperties) => {
  Object.keys(state).forEach((key) => {
    contextState[key] = state[key];
  });
};

export const setLoggingLoggedInDeviceId = (deviceId: string): void => {
  if (appInsights?.context?.device) {
    appInsights.context.device.id = deviceId;
  }
  appInsights?.context?.user?.setAuthenticatedUserContext(deviceId);
};

const getContextualState = (
  state: CustomProperties | undefined
): CustomProperties => {
  return {
    ...contextState,
    ...state,
  };
};

export const logPageView = (
  pageName: string,
  state?: CustomProperties | undefined
) => {
  if (appInsights) {
    appInsights.trackPageView({
      name: pageName,

      properties: getContextualState(state),
    });
  }
  console.log("Page opened", pageName, getContextualState(state));
  w.overlayLog?.write(
    "page",
    "Opened page " + pageName,
    getContextualState(state)
  );
};

export const logError = (
  error: Error,
  state?: CustomProperties | undefined
): void => {
  if (appInsights) {
    appInsights.trackException(
      {
        exception: error,
        severityLevel: 3,
      },
      getContextualState(state)
    );
  }
  console.error(error, getContextualState(state));
  w.overlayLog?.write("error", error, getContextualState(state));
};

export const logDebug = (
  message: string,
  state?: CustomProperties | undefined
): void => {
  if (appInsights) {
    appInsights.trackTrace(
      {
        message: message,
        severityLevel: 0,
      },
      getContextualState(state)
    );
  }
  console.debug(message, getContextualState(state));
  w.overlayLog?.write("debug", message, getContextualState(state));
};

export const logInfo = (
  message: string,
  state?: CustomProperties | undefined
): void => {
  if (appInsights) {
    appInsights.trackTrace(
      {
        message: message,
        severityLevel: 1,
      },
      getContextualState(state)
    );
  }
  console.log(message, getContextualState(state));
  w.overlayLog?.write("info", message, getContextualState(state));
};

export const logWarning = (
  message: string,
  state?: CustomProperties | undefined
): void => {
  if (appInsights) {
    appInsights.trackTrace(
      {
        message: message,
        severityLevel: 2,
      },
      getContextualState(state)
    );
  }
  console.warn(message, getContextualState(state));
  w.overlayLog?.write("warning", message, getContextualState(state));
};
