//TODO: This file does too much and should be split up based on responsibilities

import * as signalR from "@microsoft/signalr";
import { NoEmitOnErrorsPlugin } from "webpack";
import { getEnrollmentInfo } from "./enrollment-service";
import {
  addLoggingContextState,
  logDebug,
  logError,
  logInfo,
  logWarning,
  setLoggingLoggedInDeviceId,
} from "./logging";
import {
  MyOrderResponseOrder,
  OrderChangeInfo,
  OrderChangeType,
  ThemeSettings,
  WosbConnectionProperties,
  WosbConnectionSettings,
} from "./wosb-connection-contracts";

const osbVersion = "0.0.1";
const AuthorizationHeaderName = "Authorization";
const OsbVersionHeaderName = "X-WOSBVersion";
const ContentTypeHeaderName = "Content-Type";
const ApplicationJsonContentType = "application/json; charset=utf-8";

interface HandledOrderEvent {
  orderId: string;
  versionNumber: number;
}

interface OsbConnectionMessageHeader {
  id: string | undefined;
  type:
    | "OrderAdded"
    | "OrderStatusChanged"
    | "OrderUpdated"
    | "OrderArchived"
    | "BrowserRefreshRequested"
    | "DeviceSettingsChanged"
    | undefined;
}

interface OsbConnectionMessage {
  header: OsbConnectionMessageHeader | undefined;
  content: unknown;
}

const parseJwtToken = (token: string) => {
  const base64Url = token.split(".")[1];
  const missingPadding = 4 - (base64Url.length % 4);
  let padding = "";
  for (var i = 0; i < missingPadding % 4; i++) {
    padding += "=";
  }
  const base64UrlWithPadding = base64Url + padding;
  const base64 = base64UrlWithPadding.replace("-", "+").replace("_", "/");
  const json = window.atob(base64);
  return JSON.parse(json.toString());
};

interface NestedWosbConnection {
  isConnected(): boolean;
  getDeviceTheme(): Promise<ThemeSettings>;
  getOrders(): Promise<MyOrderResponseOrder[]>;

  onConnectionLost: (() => void) | undefined;
  onDisconnected: (() => void) | undefined;
  onDeviceSettingsChanged: (() => void) | undefined;
}

class DisconnectedWosbConnection implements NestedWosbConnection {
  onConnectionLost: () => void | undefined = undefined;
  onDisconnected: () => void | undefined = undefined;
  onDeviceSettingsChanged: () => void | undefined = undefined;

  isConnected(): boolean {
    return false;
  }

  getDeviceTheme(): Promise<ThemeSettings> {
    throw new Error(
      "Cannot call .getDeviceTheme() on a non-connected WosbConnection. Please call .connect() first."
    );
  }

  getOrders(): Promise<MyOrderResponseOrder[]> {
    throw new Error(
      "Cannot call .getOrders() on a non-connected WosbConnection. Please call .connect() first."
    );
  }
}

class ConnectedWosbConnection implements NestedWosbConnection {
  private connectionProperties: WosbConnectionProperties;
  private cachedThemeSettings: ThemeSettings | undefined = undefined;
  private orderIdsWhichShouldBeRemovedAccordingToLastSync: string[] = [];
  private currentlyKnownOrders: MyOrderResponseOrder[] = [];
  private recentlyHandledOrderEvents: HandledOrderEvent[] = [];
  private orderChangeCallback: (orderChangeInfo: OrderChangeInfo) => void;

  constructor(
    connectionProperties: WosbConnectionProperties,
    orderChangeCallback: (orderChangeInfo: OrderChangeInfo) => void
  ) {
    this.connectionProperties = connectionProperties;
    this.orderChangeCallback = orderChangeCallback;
  }

  isConnected(): boolean {
    return false;
  }

  onConnectionLost: (() => void) | undefined = undefined;
  onDisconnected: (() => void) | undefined = undefined;
  onDeviceSettingsChanged: (() => void) | undefined = undefined;

  async getDeviceTheme(): Promise<ThemeSettings> {
    if (this.cachedThemeSettings) {
      return this.cachedThemeSettings;
    } else {
      const themeResponse = await this.fetchFromWosbApi("/v1/device/mytheme", {
        method: "get",
        headers: this.getDefaultHeaders(),
      });
      if (themeResponse.status !== 200) {
        throw new Error("Failed retrieving device theme");
      }
      this.cachedThemeSettings = await themeResponse.json();
      if (!this.cachedThemeSettings) {
        throw new Error(
          "Retrieved new theme settings but didn't manage to get an object out of it"
        );
      }
      return this.cachedThemeSettings;
    }
  }

  getOrders(): Promise<MyOrderResponseOrder[]> {
    return this.getOrders_internal();
  }

  private mapToOrderObject(order: MyOrderResponseOrder): MyOrderResponseOrder {
    return {
      name: order.name,
      orderId: order.orderId,
      status: order.status,
      storeId: order.status,
      versionCreatedAt: order.versionCreatedAt,
      versionNumber: order.versionNumber,
      salesChannel: order.salesChannel,
      orderTypeId: order.orderTypeId,
      collectionDetails: order.collectionDetails,
    };
  }

  private async getOrders_internal(
    isInitialSetup = false
  ): Promise<MyOrderResponseOrder[]> {
    const response = await this.fetchFromWosbApi("/v1/device/myorders", {
      method: "get",
      headers: this.getDefaultHeaders(),
      cache: "no-cache",
    } as Request);
    if (response.status === 200) {
      const result = await response.json();
      if (result && result.orders != null) {
        // Wait a few seconds before we check for sync misses, so that any webhook events that were on the way when we started the call can be processed first, to reduce the number of sync misses identified
        await new Promise((resolve) => setTimeout(resolve, 4000));
        if (isInitialSetup) {
          this.currentlyKnownOrders = result.orders;
        } else {
          await this.checkSyncDataForMissedEvents(
            result.orders,
            this.currentlyKnownOrders
          );
        }
        return this.currentlyKnownOrders.map(this.mapToOrderObject);
      } else {
        this.currentlyKnownOrders = [];
        return [];
      }
    } else {
      throw new Error("Failed loading orders. " + (await response.text()));
    }
  }

  fetchFromWosbApi(
    path: string,
    options?: RequestInit | undefined
  ): Promise<Response> {
    if (!path.startsWith("/")) {
      path = "/" + path;
    }
    let baseUrl = this.connectionProperties.apiAuthority;
    if (!/^https?:\/\//.test(baseUrl)) {
      baseUrl = "https://" + baseUrl;
    }
    return window.fetch(baseUrl + path, { ...options });
  }

  private onOrderChange(
    changeType: OrderChangeType,
    order: MyOrderResponseOrder
  ): void {
    this.orderChangeCallback({
      type: changeType,
      order: {
        name: order.name,
        orderId: order.orderId,
        status: order.status,
        storeId: order.storeId,
        versionCreatedAt: order.versionCreatedAt,
        versionNumber: order.versionNumber,
        salesChannel: order.salesChannel,
        orderTypeId: order.orderTypeId,
        collectionDetails: order.collectionDetails,
      },
    });
  }

  private async checkSyncDataForMissedEvents(
    syncOrderList: MyOrderResponseOrder[],
    orders: MyOrderResponseOrder[]
  ): Promise<void> {
    const missesIdentifiedBySync: {
      orderId: string;
      lastKnownVersion: number | undefined;
      newDetectedVersion: number | undefined;
    }[] = [];
    const orderIdsWhichShouldBeRemovedAccordingToThisRun: string[] = [];

    //Create a dictionary with orderid as key and known and sync orders in the value object
    const ordersById: {
      [id: string]: {
        known?: MyOrderResponseOrder | undefined;
        sync?: MyOrderResponseOrder | undefined;
        indexInKnownList?: number | undefined;
      };
    } = {};
    syncOrderList.forEach((syncOrder) => {
      if (syncOrder.orderId) {
        ordersById[syncOrder.orderId] = { sync: syncOrder };
      }
    });
    orders.forEach((o, index) => {
      if (o.orderId) {
        if (ordersById[o.orderId]) {
          ordersById[o.orderId].known = o;
          ordersById[o.orderId].indexInKnownList = index;
        } else {
          ordersById[o.orderId] = { known: o, indexInKnownList: index };
        }
      }
    });

    //Then compare each order id
    Object.keys(ordersById).forEach((orderId) => {
      const entry = ordersById[orderId];
      if (entry.sync && entry.known) {
        if (
          entry.known.versionNumber === undefined ||
          entry.sync.versionNumber === undefined
        ) {
          // TODO: the data seems to be invalid, how should we handle this?
          return;
        }
        // If both sync entry and known entry has same version, or we already know of a newer version, we don't consider this a sync mismatch
        if (entry.known.versionNumber < entry.sync.versionNumber) {
          logDebug("Sync identified version number mismatch", {
            Order: {
              Id: entry.sync.orderId,
              Name: entry.sync.name,
            },
            LastKnownVersion: entry.known.versionNumber,
            NewDetectedVersion: entry.sync.versionNumber,
          });
          missesIdentifiedBySync.push({
            orderId: orderId,
            lastKnownVersion: entry.known.versionNumber,
            newDetectedVersion: entry.sync.versionNumber,
          });
          if (entry.indexInKnownList !== undefined) {
            // Since the sync has a newer version, replace the one we have with the one from the sync.
            orders.splice(entry.indexInKnownList, 1, entry.sync);
            this.onOrderChange("changed", entry.sync);
          }
        }
        //If we have an entry in the sync data, but didn't know about it, we have identified a miss which should be fixed
      } else if (entry.sync) {
        logDebug("Sync found missing order", {
          Order: {
            OrderId: entry.sync.orderId,
            Name: entry.sync.name,
          },
          NewDetectedVersion: entry.sync.versionNumber,
        });
        missesIdentifiedBySync.push({
          orderId: orderId,
          lastKnownVersion: undefined,
          newDetectedVersion: entry.sync.versionNumber,
        });
        // Since the sync found an order which should be there but wasn't, we need to check if it might have been recently handled and thus removed by events
        if (
          this.recentlyHandledOrderEvents.findIndex(
            (e) => e.orderId === entry.sync?.orderId
          ) === -1
        ) {
          // Since we haven't recently handled events for this order, it is likely missing because the event has not yet arrived (or that it has been lost)
          orders.push(entry.sync);
          this.onOrderChange("added", entry.sync);
        }
      } else if (entry.known) {
        // To ensure that the order didn't just arrive via event and we remove it by using old sync data, let only remove those orders which have been missing in two consecutive syncs
        if (
          this.orderIdsWhichShouldBeRemovedAccordingToLastSync.indexOf(
            entry.known.orderId || ""
          ) === -1
        ) {
          // If the order id was just found to be missing, let's just add it to the list of those which were missing during last sync
          orderIdsWhichShouldBeRemovedAccordingToThisRun.push(
            entry.known.orderId || ""
          );
        } else {
          // If the order id was missing during the previous sync too, we must have missed the event to remove it, so let's remove it now
          logDebug(
            "Sync identified an order which should not have been there",
            {
              Order: {
                OrderId: entry.known.orderId,
                Name: entry.known.name,
              },
              LastKnownVersion: entry.known.versionNumber,
            }
          );
          missesIdentifiedBySync.push({
            orderId: orderId,
            lastKnownVersion: entry.known.versionNumber,
            newDetectedVersion: undefined,
          });
          if (entry.indexInKnownList !== undefined) {
            orders.splice(entry.indexInKnownList, 1);
            this.onOrderChange("removed", entry.known);
          } else {
            //TODO: The data seems to be wrong, how should we handle this case?
          }
        }
      }
    });
    if (missesIdentifiedBySync.length > 0) {
      await this.fetchFromWosbApi(`/v1/device/order-sync-detections`, {
        method: "post",
        body: JSON.stringify({
          orderMissesDetectedBySync: missesIdentifiedBySync,
        }),
        headers: this.getDefaultHeaders(),
      });
    }
    this.orderIdsWhichShouldBeRemovedAccordingToLastSync =
      orderIdsWhichShouldBeRemovedAccordingToThisRun;
  }

  private getDefaultHeaders(): Headers {
    const headers = new Headers();
    headers.set(
      AuthorizationHeaderName,
      "Bearer " + this.connectionProperties.getAccessToken()
    );
    headers.set(ContentTypeHeaderName, ApplicationJsonContentType);
    headers.set(OsbVersionHeaderName, w?.wosbSettings?.version || osbVersion);
    return headers;
  }

  private connectSignalR(): Promise<void> {
    return new Promise((resolve, reject) => {
      const negotiationPath = "/v1/connections/wosbhub/negotiate";
      this.fetchFromWosbApi(negotiationPath, {
        method: "post",
        headers: this.getDefaultHeaders(),
      }).then(async (signalRConnectionInfoResponse) => {
        try {
          if (
            !(
              signalRConnectionInfoResponse.status >= 200 &&
              signalRConnectionInfoResponse.status < 300
            )
          ) {
            logDebug("Socket connection failed", {
              code: "device-connection-failed",
              error: `Negotiate call responded with ${
                signalRConnectionInfoResponse.status
              } and body ${await signalRConnectionInfoResponse.text()}`,
            });
            // Guard clause to fail early if the negotiate call failed.
            reject({
              code: "device-connection-failed",
              error: `Negotiate call responded with ${
                signalRConnectionInfoResponse.status
              } and body ${await signalRConnectionInfoResponse.text()}`,
            });
            return;
          }

          logDebug("Socket negotiation successful");
          const signalRConnectionInfo =
            await signalRConnectionInfoResponse.json();
          let isFirstAccessTokenRetrieval = true;
          const getSignalRConnectionAccessToken = async () => {
            if (isFirstAccessTokenRetrieval) {
              isFirstAccessTokenRetrieval = false;
              return signalRConnectionInfo.accessToken;
            } else {
              const negotiationResponse = await this.fetchFromWosbApi(
                negotiationPath,
                {
                  method: "post",
                  headers: this.getDefaultHeaders(),
                }
              );

              if (
                !(
                  negotiationResponse.status >= 200 &&
                  negotiationResponse.status < 300
                )
              ) {
                logDebug("Socket reconnection failed", {
                  code: "device-reconnection-failed",
                  error: `Negotiate call responded with ${
                    negotiationResponse.status
                  } and body ${await negotiationResponse.text()}`,
                });
                throw new Error(
                  "Failed negotiation on reconnecting device to SignalR"
                );
              }

              logDebug("Socket reconnect negotiation successful");
              return (await negotiationResponse.json())?.accessToken;
            }
          };
          const connection = new signalR.HubConnectionBuilder()
            .withUrl(signalRConnectionInfo.url, {
              accessTokenFactory: getSignalRConnectionAccessToken,
            })
            .withAutomaticReconnect()
            .build();

          connection.on("DeviceConnected", async () => {
            addLoggingContextState({
              IsConnected: true,
            });
            logInfo("Device has successfully connected");
            resolve();
          });

          connection.on("DeviceConnectionFailed", (errorInfo) => {
            //TODO: Show device connection failed info in a nice way
            logWarning(errorInfo);
            reject({ code: "device-connection-failed", error: errorInfo });
          });

          connection.on("DeviceDisconnecting", () => {
            if (this.onDisconnected) {
              this.onDisconnected();
            }
          });

          connection.on(
            "MessagesReceived",
            (messages: OsbConnectionMessage[]) => {
              messages.forEach((message: OsbConnectionMessage) => {
                const contentAsOrder = message.content as MyOrderResponseOrder;
                const isOrderEvent = /^Order/i.test(
                  message?.header?.type || ""
                );
                const orderEventMetadata: HandledOrderEvent = {
                  orderId: contentAsOrder?.orderId || "",
                  versionNumber: contentAsOrder?.versionNumber || -1,
                };
                if (isOrderEvent) {
                  if (
                    !contentAsOrder.orderId ||
                    !contentAsOrder.versionNumber
                  ) {
                    return;
                  }
                  // Skip handling any obsolete or duplicate events
                  if (
                    this.recentlyHandledOrderEvents.find(
                      (e) =>
                        e.orderId == orderEventMetadata.orderId &&
                        e.versionNumber >= orderEventMetadata.versionNumber
                    )
                  ) {
                    // logDebug(
                    //   `Received obsolete or duplicate event about order ${contentAsOrder.name}, orderId ${orderEventMetadata.orderId}, with version number ${orderEventMetadata.versionNumber}, ignoring obsolete/duplicate event.`
                    // );
                    return;
                  }
                }

                let foundIndex: number | undefined = undefined;
                switch (message?.header?.type) {
                  case "OrderAdded":
                    if (
                      !this.currentlyKnownOrders.find(
                        (o) => o.orderId === contentAsOrder.orderId
                      )
                    ) {
                      logDebug("Adding new order", {
                        Order: {
                          Id: contentAsOrder.orderId,
                          Name: contentAsOrder.name,
                        },
                      });
                      this.currentlyKnownOrders.push(contentAsOrder);
                      this.onOrderChange("added", contentAsOrder);
                    }
                    break;
                  case "OrderUpdated":
                    foundIndex = this.currentlyKnownOrders.findIndex(
                      (o) => o.orderId === contentAsOrder.orderId
                    );
                    if (foundIndex === -1) {
                      if (/finalized/i.test(contentAsOrder.status || "")) {
                        logDebug(
                          "Ignoring missing order found from update event since the order has status finalized",
                          {
                            Order: {
                              Id: contentAsOrder.orderId,
                              Name: contentAsOrder.name,
                            },
                          }
                        );
                      } else {
                        logDebug("Adding missing order based on update event", {
                          Order: {
                            Id: contentAsOrder.orderId,
                            Name: contentAsOrder.name,
                          },
                        });
                        this.currentlyKnownOrders.push(contentAsOrder);
                        this.onOrderChange("added", contentAsOrder);
                      }
                    } else {
                      if (/finalized/i.test(contentAsOrder.status || "")) {
                        logDebug(
                          "Removing finalized order based on update event",
                          {
                            Order: {
                              Id: contentAsOrder.orderId,
                              Name: contentAsOrder.name,
                            },
                          }
                        );
                        const removedOrder = this.currentlyKnownOrders.splice(
                          foundIndex,
                          1
                        );
                        this.onOrderChange("removed", removedOrder[0]);
                      } else {
                        if (
                          (this.currentlyKnownOrders[foundIndex]
                            .versionNumber || 0) <
                          (contentAsOrder.versionNumber || 0)
                        ) {
                          logDebug("Updating order based on update event", {
                            Order: {
                              Id: contentAsOrder.orderId,
                              Name: contentAsOrder.name,
                              OldStatus:
                                this.currentlyKnownOrders[foundIndex].status,
                              NewStatus: contentAsOrder.status,
                            },
                          });
                          this.currentlyKnownOrders.splice(
                            foundIndex,
                            1,
                            contentAsOrder
                          );
                          this.onOrderChange("changed", contentAsOrder);
                        }
                      }
                    }
                  case "OrderStatusChanged":
                    foundIndex = this.currentlyKnownOrders.findIndex(
                      (o) => o.orderId === contentAsOrder.orderId
                    );
                    if (foundIndex !== -1) {
                      if (/finalized/i.test(contentAsOrder.status || "")) {
                        logDebug("Removing finalized order", {
                          Order: {
                            Id: contentAsOrder.orderId,
                            Name: contentAsOrder.name,
                          },
                        });
                        const removedOrder = this.currentlyKnownOrders.splice(
                          foundIndex,
                          1
                        );
                        this.onOrderChange("removed", removedOrder[0]);
                      } else {
                        //TODO: Change to smarter way of comparing the items
                        if (
                          JSON.stringify(
                            this.currentlyKnownOrders[foundIndex]
                          ) !== JSON.stringify(contentAsOrder) &&
                          (this.currentlyKnownOrders[foundIndex]
                            .versionNumber || 0) <
                            (contentAsOrder.versionNumber || 0)
                        ) {
                          logDebug("Updating order status", {
                            Order: {
                              Id: contentAsOrder.orderId,
                              Name: contentAsOrder.name,
                              OldStatus:
                                this.currentlyKnownOrders[foundIndex].status,
                              NewStatus: contentAsOrder.status,
                            },
                          });
                          this.currentlyKnownOrders.splice(
                            foundIndex,
                            1,
                            contentAsOrder
                          );
                          this.onOrderChange("changed", contentAsOrder);
                        }
                      }
                    }
                    break;
                  case "OrderArchived":
                    foundIndex = this.currentlyKnownOrders.findIndex(
                      (o) => o.orderId === contentAsOrder.orderId
                    );
                    // Most often it will be -1 since there should already be a finalized event removing it from here.
                    if (foundIndex !== -1) {
                      logDebug("Removing archived order", {
                        Order: {
                          id: contentAsOrder.orderId,
                          name: contentAsOrder.name,
                        },
                      });
                      const removedOrder = this.currentlyKnownOrders.splice(
                        foundIndex,
                        1
                      );
                      this.onOrderChange("removed", removedOrder[0]);
                    }
                    break;
                  case "DeviceSettingsChanged":
                    if (this.onDeviceSettingsChanged) {
                      this.onDeviceSettingsChanged();
                    }
                    break;
                  case "BrowserRefreshRequested":
                    logInfo("Received request to refresh browser");
                    window.location.href = "/" + window.location.search;
                    break;
                  default:
                    logDebug(
                      "Ingored message of type " + message?.header?.type
                    );
                    break;
                }

                if (isOrderEvent && orderEventMetadata !== undefined) {
                  this.recentlyHandledOrderEvents.push(orderEventMetadata);
                  // Stop keeping track of each duplicate events after 1 minute
                  setTimeout(
                    () =>
                      this.recentlyHandledOrderEvents.splice(
                        this.recentlyHandledOrderEvents.findIndex(
                          (e) =>
                            e.orderId === orderEventMetadata.orderId &&
                            e.versionNumber === orderEventMetadata.versionNumber
                        ),
                        1
                      ),
                    1 * 60 * 1000
                  );
                }
              });
            }
          );

          logDebug("Socket preparation done, sending connection request");
          connection.onclose(() => {
            if (this.onDisconnected) {
              this.onDisconnected();
            }
          });
          connection
            .start()
            .then(() => {
              const c: any = connection;
              const logParameters: any = {};
              if (c?.connection?.transport?.constructor?.name) {
                logParameters.TransportType =
                  c?.connection?.transport?.constructor?.name;
              }
              logDebug("Socket connected, waiting for callback", logParameters);
            })
            .catch(function (err) {
              logDebug("Socket connection failed", {
                code: "Could not connect to signalR hub",
                error: err,
              });
              reject({ code: "Could not connect to signalR hub", error: err });
              logError(err);
            });
        } catch (e) {
          reject({
            code: "Failed connecting socket. Please see error info for more information",
            error: e.message,
          });
        }
      });
    });
  }

  public static async createConnection(
    connectionInfo: WosbConnectionSettings,
    orderChangeCallback: (orderChangeInfo: OrderChangeInfo) => void
  ): Promise<ConnectedWosbConnection> {
    const headers = new Headers();
    addLoggingContextState({
      DeviceId: connectionInfo.deviceId,
      IsConnected: false,
    });
    logInfo("Connecting wosb device", {
      ApiAuthority: connectionInfo.apiAuthority,
      AuthAuthority: connectionInfo.authenticationAuthority,
      ClientId: connectionInfo.clientId,
    });
    headers.set(ContentTypeHeaderName, ApplicationJsonContentType);
    headers.set(OsbVersionHeaderName, osbVersion);
    headers.set("Content-Type", "application/x-www-form-urlencoded");
    const tokenRequestUrl = /^https:\/\//.test(
      connectionInfo.authenticationAuthority
    )
      ? `${connectionInfo.authenticationAuthority}/connect/token`
      : `https://${connectionInfo.authenticationAuthority}/connect/token`;
    const authenticationClientId = connectionInfo.clientId;
    const authenticationResponse = await window.fetch(tokenRequestUrl, {
      method: "POST",
      headers: headers,
      body: `grant_type=device_login&client_id=${encodeURIComponent(
        authenticationClientId
      )}&user_id=${encodeURIComponent(
        connectionInfo.deviceId
      )}&user_secret=${encodeURIComponent(
        connectionInfo.deviceSecret
      )}&scope=${encodeURIComponent("fo:wosb offline_access")}`,
    });
    if (authenticationResponse.status === 200) {
      const responseBody = await authenticationResponse.json();
      let accessToken = responseBody.access_token;
      let refreshToken = responseBody.refresh_token;
      const parsedJwt = parseJwtToken(accessToken);
      setLoggingLoggedInDeviceId(connectionInfo.deviceId);
      addLoggingContextState({ TenantId: parsedJwt.tenant });
      logInfo("Authentication successful", {
        Resolution: {
          Height: window.innerHeight,
          Width: window.innerWidth,
        },
        UserAgent: navigator?.userAgent,
        Issuer: parsedJwt.iss,
      });

      const getMillisecondsUntilAccessTokenRenewalShouldOccur = (
        accessToken: string
      ) => {
        const parsedJwt = parseJwtToken(accessToken);
        const expiration = new Date(parsedJwt.exp * 1000);
        const renewalTime = new Date(expiration.getTime() - 15 * 60 * 1000); // Renew 15 minutes before expiration
        const millisecondsUntilRenewal =
          renewalTime.getTime() - new Date().getTime();
        return millisecondsUntilRenewal;
      };
      const getAccesstoken = () => {
        return accessToken;
      };
      const connection = new ConnectedWosbConnection(
        {
          apiAuthority: connectionInfo.apiAuthority,
          signalRAuthority: connectionInfo.socketAuthority,
          deviceId: connectionInfo.deviceId,
          getAccessToken: getAccesstoken,
          sessionId: parsedJwt.sid,
          tenantId: parsedJwt.tenant,
        },
        orderChangeCallback
      );
      const serverTimeResponse = await connection.fetchFromWosbApi(
        "/v1/device/server-time",
        {
          method: "get",
          headers: connection.getDefaultHeaders(),
        }
      );
      if (serverTimeResponse.status !== 200) {
        throw new Error(
          "Failed retrieving server time. Response code: " +
            serverTimeResponse.status
        );
      }
      const serverTime = (await serverTimeResponse.json())?.serverTime;
      if (!serverTime) {
        throw new Error(
          "Failed retrieving server time. The returned value does not contain a server time."
        );
      }
      const allowedClockSkew = 5 * 60 * 1000;
      const localTime = new Date();
      if (
        Math.abs(new Date(serverTime).getTime() - localTime.getTime()) >
        allowedClockSkew
      ) {
        const error: any = new Error(
          `The server clock is ${serverTime}, but the local clock is ${localTime.toISOString()}. This is more than the allowed clock skew of ${allowedClockSkew} milliseconds.`
        );
        error.errorType = "invalid-clock";
        error.serverTime = new Date(serverTime).toISOString();
        error.localTime = localTime.toISOString();
        throw error;
      }
      let renewAccessToken: () => Promise<void> = () => Promise.resolve();
      renewAccessToken = async () => {
        const tokenRefreshResponse = await window.fetch(tokenRequestUrl, {
          method: "POST",
          headers: headers,
          body: `grant_type=refresh_token&client_id=${encodeURIComponent(
            authenticationClientId
          )}&refresh_token=${refreshToken}`,
        });
        // If the request was not successful, we need to refresh the page or something
        if (tokenRefreshResponse.status !== 200) {
          if (connection.onConnectionLost) {
            connection.onConnectionLost();
          }
        }
        const tokenRefreshBody = await tokenRefreshResponse.json();
        accessToken = tokenRefreshBody.access_token;
        refreshToken = tokenRefreshBody.refresh_token;
        setTimeout(
          renewAccessToken,
          getMillisecondsUntilAccessTokenRenewalShouldOccur(accessToken)
        );
      };
      if (parsedJwt.tenant !== "bb") {
        setTimeout(
          renewAccessToken,
          getMillisecondsUntilAccessTokenRenewalShouldOccur(accessToken)
        );
      }
      const isInitialSetup = true;
      logDebug("Retrieving initial order set");
      await connection.getOrders_internal(isInitialSetup);
      logDebug("Connecting socket");
      await connection.connectSignalR();
      logDebug("Connection set up done");
      return connection;
    } else {
      if (
        /application\/json/i.test(
          authenticationResponse.headers?.get("Content-Type") || ""
        )
      ) {
        let invalidCredentialsError: any | undefined = undefined;
        try {
          const errorResponseObject = await authenticationResponse.json();
          if (
            errorResponseObject &&
            errorResponseObject.error === "invalid_grant"
          ) {
            invalidCredentialsError = new Error(
              "Invalid Failed to connect the device with the provided credentials. Error returned: Invalid grant."
            );
            invalidCredentialsError.errorType = "invalid-credentials";
          }
        } catch {}
        if (invalidCredentialsError) {
          throw invalidCredentialsError;
        } else {
          throw new Error(
            `Failed to connect the device with the provided credentials. Result code: ${
              authenticationResponse.status
            }, response content: ${await authenticationResponse.text()}`
          );
        }
      } else {
        throw new Error(
          `Failed to connect the device with the provided credentials. Result code: ${
            authenticationResponse.status
          }. Response content is of type ${authenticationResponse.headers.get(
            "Content-Type"
          )}.`
        );
      }
    }
  }
}

export class WosbConnection {
  private realConnection: NestedWosbConnection;
  private connectionSettings: WosbConnectionSettings;
  private orderChangeListeners: {
    id: number;
    listener: (orderChangeInfo: OrderChangeInfo) => void;
  }[] = [];
  private lastChangeListenerId = 0;
  private isConnecting = false;

  onConnectionLost: (() => void) | undefined = undefined;
  onDisconnected: (() => void) | undefined = undefined;
  onDeviceSettingsChanged: (() => void) | undefined = undefined;

  constructor(connectionSettings: WosbConnectionSettings) {
    this.connectionSettings = connectionSettings;
    this.realConnection = new DisconnectedWosbConnection();
  }

  public getDeviceId(): string {
    return this.connectionSettings.deviceId;
  }

  public isConnected(): boolean {
    return this.realConnection.isConnected();
  }

  async getDeviceTheme(): Promise<ThemeSettings> {
    return this.realConnection.getDeviceTheme();
  }

  private onOrderChange_Internal(orderChangeInfo: OrderChangeInfo): void {
    this.orderChangeListeners.forEach((l) => {
      l.listener(orderChangeInfo);
    });
  }

  public async getOrders(): Promise<MyOrderResponseOrder[]> {
    return await this.realConnection.getOrders();
  }

  addOrderChangeListener(
    callback: (orderChangeInfo: OrderChangeInfo) => void
  ): { dispose: () => void } {
    const id = this.lastChangeListenerId++;
    this.orderChangeListeners.push({
      id: id,
      listener: callback,
    });
    return {
      dispose: () => {
        const index = this.orderChangeListeners.findIndex((l) => l.id === id);
        if (index !== -1) {
          this.orderChangeListeners.splice(index, 1);
        }
      },
    };
  }

  public async connect(): Promise<void> {
    if (this.isConnecting) {
      throw new Error(
        "Invalid to try to connect twice with the same wosb-connection"
      );
    }
    this.isConnecting = true;
    this.realConnection = await ConnectedWosbConnection.createConnection(
      this.connectionSettings,
      (change) => this.onOrderChange_Internal(change)
    );
    this.realConnection.onConnectionLost = () => {
      if (this.onConnectionLost) {
        this.onConnectionLost();
      }
    };
    this.realConnection.onDeviceSettingsChanged = () => {
      if (this.onDeviceSettingsChanged) {
        this.onDeviceSettingsChanged();
      }
    };
    this.realConnection.onDisconnected = () => {
      if (this.onDisconnected) {
        this.onDisconnected();
      }
    };
    setInterval(async () => {
      try {
        // Poll to get orders to ensure we don't miss orders. Events regarding identified changes will be sent by the real connectino.
        this.realConnection.getOrders();
      } catch (e) {
        if (this.onConnectionLost) {
          logWarning("Failed while polling for order changes.", {
            ErrorInfo: e.toString(),
          });
          this.onConnectionLost();
        }
      }
    }, 15000);
  }
}

const query = new URLSearchParams(window.location.search);
const hasSchemeRegex = new RegExp("^https?://");
const ensureSchemeIfNonNull = (url: string | null): string | null => {
  if (url === null) {
    return null;
  }
  if (hasSchemeRegex.test(url)) {
    return url;
  } else {
    return "https://" + url;
  }
};
function getValueOrDefault(
  value: string | undefined | null,
  defaultValue: string
): string {
  if (value) {
    return value;
  } else {
    return defaultValue;
  }
}

const queryParameterNames = {
  api_authority: "api_authority",
  tenant_id: "tenant_id",
  authentication_authority: "auth_authority",
  socket_authority: "socket_authority",
  client_id: "client_id",
  device_id: "device_id",
  device_secret: "device_secret",
};

function getQueryValidationResults(): {
  isValid: boolean;
  missingMandatoryParameters: string[];
} {
  const missingParameters: string[] = [];
  const checkMandatoryParameter = (paramName: string) => {
    if (query.get(paramName) === null) {
      missingParameters.push(paramName);
    }
  };
  checkMandatoryParameter(queryParameterNames.client_id);
  checkMandatoryParameter(queryParameterNames.device_id);
  checkMandatoryParameter(queryParameterNames.device_secret);
  if (
    query.get(queryParameterNames.tenant_id) === null &&
    queryParameterNames.authentication_authority === null
  ) {
    missingParameters.push(queryParameterNames.tenant_id);
  }
  return {
    isValid: missingParameters.length === 0,
    missingMandatoryParameters: missingParameters,
  };
}

const w: any = window;

export const queryValidationResults = getQueryValidationResults();
const enrollmentInfo = getEnrollmentInfo();
const apiAuthority =
  ensureSchemeIfNonNull(query.get(queryParameterNames.api_authority)) ||
  enrollmentInfo.apiAuthority ||
  ensureSchemeIfNonNull(
    getValueOrDefault(w?.wosbSettings?.apiAuthority, "wosb.futureordering.com")
  );
export const connection = new WosbConnection({
  apiAuthority: apiAuthority,
  authenticationAuthority:
    ensureSchemeIfNonNull(
      query.get(queryParameterNames.authentication_authority)
    ) ||
    enrollmentInfo.authenticationAuthority ||
    query.get(queryParameterNames.tenant_id) +
      "." +
      getValueOrDefault(
        w?.wosbSettings?.authenticationAuthority,
        "login.futureordering.com"
      ),
  socketAuthority:
    ensureSchemeIfNonNull(query.get(queryParameterNames.socket_authority)) ||
    enrollmentInfo.apiAuthority ||
    apiAuthority,
  clientId:
    query.get(queryParameterNames.client_id) ||
    w?.wosbSettings?.clientId ||
    "invalid-connection-value-not-provided",
  deviceId:
    query.get(queryParameterNames.device_id) ||
    enrollmentInfo.deviceId ||
    "invalid-connection-value-not-provided",
  deviceSecret:
    query.get(queryParameterNames.device_secret) ||
    enrollmentInfo.deviceSecret ||
    "invalid-connection-value-not-provided",
});
