import { IComponent } from "../component-interfaces";
import * as logoImage from "./logo_600.png";

export class SplashScreen implements IComponent {
  content : HTMLElement[] | undefined = undefined;
  constructor(textOrContent? : string | HTMLElement[] | undefined){
    if (textOrContent){
      if (typeof(textOrContent) === 'string'){
        const paragraph = document.createElement('h1');
        paragraph.innerText = textOrContent;
        this.content = [paragraph];
      }
      else{
        this.content = textOrContent;
      }
    }
  }

  private rootElement : HTMLElement | undefined = undefined;
  getRootElement: () => HTMLElement = () => {
    if (this.rootElement == undefined){
      const container = document.createElement("div");
      container.className = "splash-screen-container";
      const centeredItem = document.createElement("div");
      const logo = document.createElement("img");
      logo.alt = "Future Ordering logo";
      logo.height = 300;
      logo.style.marginBottom = '50px';
      logo.src = logoImage;

      container.appendChild(centeredItem);
      centeredItem.appendChild(logo);
      if (this.content !== undefined){
        this.content.forEach(c => centeredItem.appendChild(c));
      }
      this.rootElement = container;
    }
    return this.rootElement;
  };

  static createStyleElement = () => {
    const style = document.createElement("style");
    style.innerHTML = `
      .splash-screen-container {
          margin: 0;
          height: 100%;
          width: 100%;
          padding: 0;
          display: table;
      }
      .splash-screen-container > div {
          display: table-cell;
          vertical-align: middle;
          text-align: center;
      }`;
    return style;
  };
}
