const query = new URLSearchParams(window.location.search);
const hasSchemeRegex = new RegExp("^https?://");
const ensureSchemeIfNonNull = (url: string | null): string | null => {
  if (url === null) {
    return null;
  }
  if (hasSchemeRegex.test(url)) {
    return url;
  } else {
    return "https://" + url;
  }
};
function getValueOrDefault(
  value: string | undefined | null,
  defaultValue: string
): string {
  if (value) {
    return value;
  } else {
    return defaultValue;
  }
}

export interface EnrollmentInfo {
  tenantId: string | undefined,
  apiAuthority: string | undefined;
  authenticationAuthority: string | undefined;
  deviceId: string | undefined;
  deviceSecret: string | undefined;
}

const w: any = window;
const apiAuthority =
  ensureSchemeIfNonNull(query.get("api_authority")) ||
  ensureSchemeIfNonNull(getValueOrDefault(w?.wosbSettings?.apiAuthority, "wosb.futureordering.com"));

const enrollmentLocalstorageKeys = {
  wosbEnrollmentTenantId: "wosb-enrollment-tenantId",
  wosbEnrollmentApiauthority: "wosb-enrollment-apiauthority",
  wosbEnrollmentAuthenticationauthority:
    "wosb-enrollment-authenticationauthority",
  wosbEnrollmentDeviceid: "wosb-enrollment-deviceid",
  wosbEnrollmentDevicesecret: "wosb-enrollment-devicesecret",
};

export const tryReenroll: (
  reenrollmentCode: string
) => Promise<"successful" | "invalid-code" | "unknown-failure"> = async (
  reenrollmentCode: string
) => {
  const oldEnrollmentInfo = getEnrollmentInfo();
  const enrollmentResponse = await fetch(
    `${oldEnrollmentInfo.apiAuthority}/v1/reenrollments/${encodeURIComponent(
      reenrollmentCode
    )}/reenroll-device`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        deviceId: oldEnrollmentInfo.deviceId,
        tenantId: oldEnrollmentInfo.tenantId
      }),
    }
  );
  if (enrollmentResponse.status === 200) {
    try {
      const enrollmentResult = await enrollmentResponse.json();
      if (enrollmentResult.deviceSecret) {
        localStorage.setItem(
          enrollmentLocalstorageKeys.wosbEnrollmentDevicesecret,
          enrollmentResult.devicesecret
        );
        return "successful";
      } else {
        return "unknown-failure";
      }
    } catch {
      return "unknown-failure";
    }
  } else if (enrollmentResponse.status === 400) {
    // TODO: We should probably check the response body as well, to check the error code, but right now we just assume it's only the code that was the issue.
    return "invalid-code";
  } else {
    return "unknown-failure";
  }
};

export const tryEnroll: (
  enrollmentCode: string
) => Promise<"successful" | "invalid-code" | "unknown-failure"> = async (
  enrollmentCode: string
) => {
  const enrollmentResponse = await fetch(
    `${apiAuthority}/v1/enrollments/${encodeURIComponent(
      enrollmentCode
    )}/enroll-device`,
    { method: "POST" }
  );
  if (enrollmentResponse.status === 200) {
    try {
      const enrollmentResult =
        (await enrollmentResponse.json()) as EnrollmentInfo;
      if (
        enrollmentResult.tenantId &&
        enrollmentResult.apiAuthority &&
        enrollmentResult.authenticationAuthority &&
        enrollmentResult.deviceId &&
        enrollmentResult.deviceSecret
      ) {
        clearEnrollmentInfo();
        const persistValueIfNotNull = (key: string, value: string) => {
          if (value) {
            localStorage.setItem(key, value);
          }
        };
        persistValueIfNotNull(
          enrollmentLocalstorageKeys.wosbEnrollmentTenantId,
          enrollmentResult.tenantId
        );
        persistValueIfNotNull(
          enrollmentLocalstorageKeys.wosbEnrollmentApiauthority,
          enrollmentResult.apiAuthority
        );
        persistValueIfNotNull(
          enrollmentLocalstorageKeys.wosbEnrollmentAuthenticationauthority,
          enrollmentResult.authenticationAuthority
        );
        persistValueIfNotNull(
          enrollmentLocalstorageKeys.wosbEnrollmentDeviceid,
          enrollmentResult.deviceId
        );
        persistValueIfNotNull(
          enrollmentLocalstorageKeys.wosbEnrollmentDevicesecret,
          enrollmentResult.deviceSecret
        );
        return "successful";
      } else {
        return "unknown-failure";
      }
    } catch {
      return "unknown-failure";
    }
  } else if (enrollmentResponse.status === 400) {
    // TODO: We should probably check the response body as well, to check the error code, but right now we just assume it's only the code that was the issue.
    return "invalid-code";
  } else {
    return "unknown-failure";
  }
};

export const getEnrollmentInfo: () => EnrollmentInfo = () => {
  return {
    tenantId: localStorage.getItem(
      enrollmentLocalstorageKeys.wosbEnrollmentTenantId
    ),
    apiAuthority: localStorage.getItem(
      enrollmentLocalstorageKeys.wosbEnrollmentApiauthority
    ),
    authenticationAuthority: localStorage.getItem(
      enrollmentLocalstorageKeys.wosbEnrollmentAuthenticationauthority
    ),
    deviceId: localStorage.getItem(
      enrollmentLocalstorageKeys.wosbEnrollmentDeviceid
    ),
    deviceSecret: localStorage.getItem(
      enrollmentLocalstorageKeys.wosbEnrollmentDevicesecret
    ),
  };
};

export const hasEnrollmentDeviceInfo = () => {
  const info = getEnrollmentInfo();
  return (
    info.tenantId &&
    info.apiAuthority &&
    info.authenticationAuthority &&
    info.deviceId
  );
};

export const hasEnrollmentSecret = () => {
  return Boolean(getEnrollmentInfo().deviceSecret);
};

export const clearEnrollmentSecret = () => {
  localStorage.removeItem(
    enrollmentLocalstorageKeys.wosbEnrollmentDevicesecret
  );
};

export const clearEnrollmentInfo = () => {
  localStorage.removeItem(
    enrollmentLocalstorageKeys.wosbEnrollmentTenantId
  );
  localStorage.removeItem(
    enrollmentLocalstorageKeys.wosbEnrollmentApiauthority
  );
  localStorage.removeItem(
    enrollmentLocalstorageKeys.wosbEnrollmentAuthenticationauthority
  );
  localStorage.removeItem(enrollmentLocalstorageKeys.wosbEnrollmentDeviceid);
  localStorage.removeItem(
    enrollmentLocalstorageKeys.wosbEnrollmentDevicesecret
  );
};
