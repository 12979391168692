import {
  clearEnrollmentInfo,
  getEnrollmentInfo,
  hasEnrollmentDeviceInfo,
  tryEnroll,
  tryReenroll,
} from "../../full/src/services/enrollment-service";
import { SplashScreen } from "./components/splash-screen/splash-screen";

const delay = (time: number) =>
  new Promise<void>((resolve) => {
    setTimeout(() => resolve(), time);
  });

const rootElement = document.getElementById("root");

const setRootContent = (
  content: HTMLElement,
  background?: string | undefined
) => {
  if (background) {
    document.body.style.background = background;
  } else {
    document.body.style.background = null;
  }
  rootElement.innerHTML = "";
  rootElement.appendChild(content);
};

const createElementWithInnerText = (elementName: string, innerText: string) => {
  const element = document.createElement(elementName);
  element.innerText = innerText;
  return element;
};

const reloadApplication = () => {
  location.reload();
};

export const startEnrollmentApp = async () => {
  const splashScreen = new SplashScreen([
    createElementWithInnerText("h1", "Order Status Board"),
    createElementWithInnerText("p", "Loading device enrollment"),
    createElementWithInnerText("p", "Powered by Future Ordering"),
  ]);
  setRootContent(splashScreen.getRootElement());
  await delay(3000);
  if (hasEnrollmentDeviceInfo()) {
    const enrollmentInfo = getEnrollmentInfo();
    const form = document.createElement("form");
    const input = document.createElement("input");
    input.type = "number";
    input.placeholder = "Re-enrollment Code";
    input.style.fontSize = "1.5em";
    input.style.textAlign = "center";
    input.style.marginTop = "1em";
    input.style.padding = '20px';
    input.style.border = '1px solid black';
    input.style.width = "300px";
    input.style.boxSizing = 'border-box';
    const button = document.createElement("button");
    button.innerText = "Re-enroll Device";
    button.style.fontSize = "1.5em";
    button.style.padding = '20px';
    button.style.width = "300px";
    button.style.boxSizing = 'border-box';
    button.type = 'submit';
    button.disabled = true;
    const errorInfoSpace = document.createElement("p");
    errorInfoSpace.style.color = "red";
    errorInfoSpace.innerHTML = '&nbsp;'
    form.appendChild(input);
    form.appendChild(errorInfoSpace);
    form.appendChild(button);
    const clearEnrollmentInfoLink = document.createElement("a");
    clearEnrollmentInfoLink.addEventListener("click", () =>
      clearEnrollmentInfo()
    );
    clearEnrollmentInfoLink.href = "/" + window.location.search;
    input.addEventListener("input", () => {
      button.disabled = !Boolean(input.value);
      errorInfoSpace.innerHTML = '&nbsp;'
    });
    form.addEventListener("submit", async (e) => {
      e.preventDefault();
      button.disabled = true;
      input.disabled = true;
      try {
        const result = await tryReenroll(input.value);
        if (result === "successful") {
          reloadApplication();
        } else {
          if (result === "invalid-code") {
            errorInfoSpace.innerText = "Re-enrollment code was not valid";
          } else if (result === "unknown-failure") {
            errorInfoSpace.innerText =
              "Unknown failure trying to re-enroll device";
          }
          input.disabled = false;
        }
      } catch (err) {
        errorInfoSpace.innerText = err.message;
      }
      return false;
    });
    setRootContent(
      new SplashScreen([
        createElementWithInnerText('h1', 'Order Status Board'),
        createElementWithInnerText(
          "p",
          "Device Id: " + enrollmentInfo.deviceId
        ),

        createElementWithInnerText(
          "p",
          "Please start a re-enrollment session and enter the code here."
        ),
        form,
      ]).getRootElement()
    );
  } else {
    const form = document.createElement("form");
    const input = document.createElement("input");
    input.type = "number";
    input.style.fontSize = "1.5em";
    input.style.textAlign = "center";
    input.style.marginTop = "1em";
    input.style.padding = '20px';
    input.style.border = '1px solid black';
    input.style.width = "300px";
    input.style.boxSizing = 'border-box';
    input.placeholder = "Enrollment Code";
    const button = document.createElement("button");
    button.innerText = "Enroll Device";
    button.style.fontSize = "1.5em";
    button.style.padding = "20px";
    button.style.width = "300px";
    button.style.boxSizing = 'border-box';
    button.type = 'submit';
    button.disabled = true;
    const errorInfoSpace = document.createElement("p");
    errorInfoSpace.style.color = "red";
    errorInfoSpace.innerHTML = '&nbsp;'
    form.appendChild(input);
    form.appendChild(errorInfoSpace);
    form.appendChild(button);
    input.addEventListener("input", () => {
      button.disabled = !Boolean(input.value);
      errorInfoSpace.innerHTML = '&nbsp;'
    });
    form.addEventListener("submit", async (e) => {
      e.preventDefault();
      button.disabled = true;
      input.disabled = true;
      try {
        const result = await tryEnroll(input.value);
        if (result === "successful") {
          reloadApplication();
        } else {
          if (result === "invalid-code") {
            errorInfoSpace.innerText = "Enrollment code was not valid";
          } else if (result === "unknown-failure") {
            errorInfoSpace.innerText =
              "Unknown failure trying to enroll device";
          }
          input.disabled = false;
        }
      } catch (err) {
        errorInfoSpace.innerText = err.message;
      }
      return false;
    });
    setRootContent(
      new SplashScreen([
        createElementWithInnerText('h1', 'Order Status Board'),
        createElementWithInnerText(
          "p",
          "Please start an enrollment session and enter the code here."
        ),
        form,
      ]).getRootElement()
    );
  }
};
