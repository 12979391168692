import { WosbConnection } from "./wosb-connection";
import { OrderChangeInfo } from "./wosb-connection-contracts";

export interface OrderChangeQueue {
  enqueue(change: OrderChangeInfo): void;
  dequeue(): Promise<OrderChangeInfo>;
  getCount(): number;
}

export class InMemoryOrderChangeQueue implements OrderChangeQueue {
  queue: OrderChangeInfo[] = [];
  resolveCurrentDequeueOperation: ((change: OrderChangeInfo) => void) | null =
    null;

  enqueue(change: OrderChangeInfo): void {
    if (this.resolveCurrentDequeueOperation === null) {
      this.queue.push(change);
    } else {
      this.resolveCurrentDequeueOperation(change);
      this.resolveCurrentDequeueOperation = null;
    }
  }

  dequeue(): Promise<OrderChangeInfo> {
    if (this.resolveCurrentDequeueOperation !== null) {
      throw new Error(
        "Not allowed to have multiple dequeue operations ongoing at the same time"
      );
    }
    if (this.queue.length > 0) {
      return Promise.resolve(this.queue.shift());
    } else {
      return new Promise((resolve) => {
        this.resolveCurrentDequeueOperation = resolve;
      });
    }
  }

  getCount(): number {
    return this.queue.length;
  }
}

export const createOrderChangeQueue = async (connection: WosbConnection) : Promise<OrderChangeQueue> => {
    if (!connection.isConnected){
        throw new Error("Cannot create an order change queue for a non-connected wosbconnection");
    }
    const queue = new InMemoryOrderChangeQueue();

    connection.addOrderChangeListener(change => queue.enqueue(change));
    const currentOrders = await connection.getOrders();
    for(let i = 0; i < currentOrders.length; i++){
        queue.enqueue({
            type: 'added', 
            order: currentOrders[i]
        });
    }
    return queue;
};