import { ICondition } from "../../helpers/condition-helpers";
import { OrderChangeQueue } from "../../services/order-change-queue";
import {
  LayoutType,
  OrderChangeInfo,
} from "../../services/wosb-connection-contracts";
import {
  GridLayoutAnimationSetting,
  GridLayoutSettings,
} from "../grid-layout/grid-layout-settings";

export interface HorizontalSplitLayoutHeaderSettings {
  main?: HorizontalSplitLayoutHeaderSetting | undefined;
  left?: HorizontalSplitLayoutHeaderSetting | undefined;
  right?: HorizontalSplitLayoutHeaderSetting | undefined;
}

export interface HorizontalSplitLayoutHeaderSetting {
  text?: string | undefined;
  fontSize?: number | undefined;
  fontFamily?: string | undefined;
  fontWeight?: string | undefined;
  fontStyle?: string | undefined;
  justify?: string | undefined;
  align?: string | undefined;
  color?: string | undefined;
  padding?: string | undefined;
}

export interface AnimationSpeedThresholds {
  queueCount: number;
  speed: number;
}

export interface OrderStatusLayout extends HTMLElement {
  getLayoutType(): LayoutType;
  applyQueue(queue: OrderChangeQueue): Promise<{ abort: () => void }>;
  applyChange(change: OrderChangeInfo): Promise<void>;
  setAnimationSpeedMultiplier(multiplier: number): void;
}

export interface HorizontalSplitLayoutSettings {
  rootGridTemplate: string | undefined;
  headers: HorizontalSplitLayoutHeaderSettings;
  leftGrid: GridLayoutSettings;
  rightGrid: GridLayoutSettings;
  animationThresholds: AnimationSpeedThresholds[];
  filter: ICondition[] | undefined;
}

export const getWithDefaultsForMissingSettings = (
  settings: HorizontalSplitLayoutSettings
): HorizontalSplitLayoutSettings => {
  const valueOrDefault = (
    val: number | null | undefined,
    _default: number
  ): number => {
    if (val === null || val === undefined) {
      return _default;
    } else {
      return val;
    }
  };
  const getAnimationSettingsOrDefault = (
    animationSettings: GridLayoutAnimationSetting | undefined | null,
    defaultDuration: number,
    defaultEndDelay: number
  ): GridLayoutAnimationSetting => {
    return {
      duration: valueOrDefault(animationSettings?.duration, defaultDuration),
      endDelay: valueOrDefault(animationSettings?.endDelay, defaultEndDelay),
    };
  };
  if (!settings) {
    settings = {} as any;
  }
  return {
    filter: settings.filter,
    animationThresholds:
      settings.animationThresholds?.length > 0
        ? settings.animationThresholds
        : [
            { queueCount: 60, speed: 8 },
            { queueCount: 40, speed: 4 },
            { queueCount: 20, speed: 2 },
            { queueCount: 10, speed: 1.5 },
          ],
    headers: {
      left: {
        text:
          settings?.headers?.left?.text === undefined
            ? "Preparing"
            : settings?.headers?.left?.text,
        align:
          settings?.headers?.left?.align === undefined
            ? "center"
            : settings?.headers?.left?.align,
        color:
          settings?.headers?.left?.color === undefined
            ? "black"
            : settings?.headers?.left?.color,
        fontFamily: settings?.headers?.left?.fontFamily,
        fontSize:
          settings?.headers?.left?.fontSize === undefined
            ? 80
            : settings?.headers?.left?.fontSize,
        fontStyle: settings?.headers?.left?.fontStyle,
        fontWeight: settings?.headers?.left?.fontWeight,
        justify:
          settings?.headers?.left?.justify === undefined
            ? "center"
            : settings?.headers?.left?.justify,
        padding:
          settings?.headers?.left?.padding === undefined
            ? "0px"
            : settings?.headers?.left?.padding,
      },
      right: {
        text:
          settings?.headers?.right?.text === undefined
            ? "Ready"
            : settings?.headers?.right?.text,
        align:
          settings?.headers?.right?.align === undefined
            ? "center"
            : settings?.headers?.right?.align,
        color:
          settings?.headers?.right?.color === undefined
            ? "black"
            : settings?.headers?.right?.color,
        fontFamily: settings?.headers?.right?.fontFamily,
        fontSize:
          settings?.headers?.right?.fontSize === undefined
            ? 80
            : settings?.headers?.right?.fontSize,
        fontStyle: settings?.headers?.right?.fontStyle,
        fontWeight: settings?.headers?.right?.fontWeight,
        justify:
          settings?.headers?.right?.justify === undefined
            ? "center"
            : settings?.headers?.right?.justify,
        padding:
          settings?.headers?.right?.padding === undefined
            ? "0px"
            : settings?.headers?.right?.padding,
      },
      main: {
        text: settings?.headers?.main?.text,
        align:
          settings?.headers?.main?.align === undefined
            ? "center"
            : settings?.headers?.main?.align,
        color:
          settings?.headers?.main?.color === undefined
            ? "black"
            : settings?.headers?.main?.color,
        fontFamily: settings?.headers?.main?.fontFamily,
        fontSize:
          settings?.headers?.main?.fontSize === undefined
            ? 100
            : settings?.headers?.main?.fontSize,
        fontStyle: settings?.headers?.main?.fontStyle,
        fontWeight: settings?.headers?.main?.fontWeight,
        justify:
          settings?.headers?.main?.justify === undefined
            ? "center"
            : settings?.headers?.main?.justify,
        padding:
          settings?.headers?.main?.padding === undefined
            ? "0px"
            : settings?.headers?.main?.padding,
      },
    },
    leftGrid: {
      filter: settings.leftGrid?.filter,
      layoutRules:
        settings.leftGrid?.layoutRules?.length > 0
          ? settings.leftGrid.layoutRules
          : [
              {
                rows: 4,
                columns: 3,
                gap: {
                  height: 30,
                  width: 30,
                },
              },
              {
                rows: 5,
                columns: 4,
                gap: {
                  height: 25,
                  width: 25,
                },
              },
              {
                rows: 7,
                columns: 5,
                gap: {
                  height: 20,
                  width: 20,
                },
              },
            ],
      animations: {
        animateIn: getAnimationSettingsOrDefault(
          settings?.leftGrid?.animations?.animateIn,
          750,
          250
        ),
        animateOut: getAnimationSettingsOrDefault(
          settings?.leftGrid?.animations?.animateOut,
          1000,
          0
        ),
        moveItem: getAnimationSettingsOrDefault(
          settings?.leftGrid?.animations?.moveItem,
          750,
          250
        ),
      },
      cardStyle: {
        activityText: settings?.leftGrid?.cardStyle?.activityText,
        background: settings?.leftGrid?.cardStyle?.background,
        border:
          settings?.leftGrid?.cardStyle?.border === undefined
            ? "solid 9px gray"
            : settings?.leftGrid?.cardStyle?.border,
        borderRadius: settings?.leftGrid?.cardStyle?.borderRadius,
        color: settings?.leftGrid?.cardStyle?.color,
        conditional: settings?.leftGrid?.cardStyle?.conditional,
        fontFamily: settings?.leftGrid?.cardStyle?.fontFamily,
        fontSizeMultiplier:
          settings?.leftGrid?.cardStyle?.fontSizeMultiplier === undefined
            ? 1
            : settings?.leftGrid?.cardStyle?.fontSizeMultiplier,
        fontStyle: settings?.leftGrid?.cardStyle?.fontStyle,
        fontWeight: settings?.leftGrid?.cardStyle?.fontWeight,
      },
    },
    rightGrid: {
      filter: settings.rightGrid?.filter,
      layoutRules:
        settings.rightGrid?.layoutRules?.length > 0
          ? settings.rightGrid.layoutRules
          : [
              {
                rows: 4,
                columns: 3,
                emphasizedRowCount: 2,
                gap: {
                  height: 30,
                  width: 30,
                },
              },
              {
                rows: 5,
                columns: 4,
                emphasizedRowCount: 2,
                gap: {
                  height: 25,
                  width: 25,
                },
              },
              {
                rows: 7,
                columns: 5,
                emphasizedRowCount: 3,
                gap: {
                  height: 20,
                  width: 20,
                },
              },
            ],
      animations: {
        animateIn: getAnimationSettingsOrDefault(
          settings?.rightGrid?.animations?.animateIn,
          2500,
          2000
        ),
        animateOut: getAnimationSettingsOrDefault(
          settings?.rightGrid?.animations?.animateOut,
          1000,
          0
        ),
        moveItem: getAnimationSettingsOrDefault(
          settings?.rightGrid?.animations?.moveItem,
          750,
          250
        ),
      },
      cardStyle: {
        activityText: settings?.rightGrid?.cardStyle?.activityText,
        background:
          settings?.rightGrid?.cardStyle?.background === undefined
            ? "green"
            : settings?.rightGrid?.cardStyle?.background,
        border:
          settings?.rightGrid?.cardStyle?.border === undefined
            ? "solid 9px green"
            : settings?.rightGrid?.cardStyle?.border,
        borderRadius: settings?.rightGrid?.cardStyle?.borderRadius,
        color:
          settings?.rightGrid?.cardStyle?.color === undefined
            ? "white"
            : settings?.rightGrid?.cardStyle?.color,
        conditional: settings?.rightGrid?.cardStyle?.conditional,
        fontFamily: settings?.rightGrid?.cardStyle?.fontFamily,
        fontSizeMultiplier:
          settings?.rightGrid?.cardStyle?.fontSizeMultiplier === undefined
            ? 1
            : settings?.rightGrid?.cardStyle?.fontSizeMultiplier,
        fontStyle: settings?.rightGrid?.cardStyle?.fontStyle,
        fontWeight: settings?.rightGrid?.cardStyle?.fontWeight,
      },
    },
    rootGridTemplate:
      settings?.rootGridTemplate ||
      "0px 180px 1fr 50px / 50px 20fr 1fr 20fr 50px",
  };
};
