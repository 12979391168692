import { HorizontalSplitLayout } from "./horizontal-split-layout/horizontal-split-layout";
import { OrderCard } from "./order-card/order-card";
import { OrderGrid } from "./order-grid/order-grid";
import { PortraitGridLayout } from "./portrait-grid-layout/portrait-grid-layout";
import { SplashScreen } from "./splash-screen/splash-screen";

export const loadComponentStyles = () => {
    const styles = [
        SplashScreen.createStyleElement(),
        HorizontalSplitLayout.createStyleElement(),
        PortraitGridLayout.createStyleElement(),
        OrderGrid.createStyleElement(),
        OrderCard.createStyleElement()
    ];
    styles.forEach(s => document.head.appendChild(s));
};