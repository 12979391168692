import {
  GridLayoutRule,
  GridLayoutSettings,
} from "../../../../full/src/components/grid-layout/grid-layout-settings";
import { testConditions } from "../../../../full/src/helpers/condition-helpers";
import { MyOrderResponseOrder } from "../../../../full/src/services/wosb-connection-contracts";
import { IComponent } from "../component-interfaces";
import { OrderCard } from "../order-card/order-card";

// This class is created since the Qupzilla browser doesn't have "DOMRect", but instead uses something it calls ClientRect.
export class CardSpotRect {
  left: number;
  top: number;
  width: number;
  height: number;
  constructor(left: number, top: number, width: number, height: number) {
    this.left = left;
    this.top = top;
    this.width = width;
    this.height = height;
  }
}

interface CardSpot {
  getLocationRect: () => CardSpotRect;
  card: OrderCard | undefined;
  // TODO: Add the attached order card here, to make it easier to keep track of occupied places
}

export class OrderGrid implements IComponent {
  private static lastOrderGridId = 0;
  private orderGridId: number | undefined = undefined;
  private settings: GridLayoutSettings;
  private currentLayoutRule: GridLayoutRule;
  private currentCardSpots: CardSpot[];
  private defaultFilter: (order: MyOrderResponseOrder) => boolean;

  constructor(
    settings: GridLayoutSettings,
    defaultFilter: (order: MyOrderResponseOrder) => boolean
  ) {
    this.orderGridId = ++OrderGrid.lastOrderGridId;
    this.defaultFilter = defaultFilter;
    this.settings = settings;
    if (!this.settings.layoutRules || this.settings.layoutRules.length === 0) {
      throw new Error(
        "No layout rules specified for the grid. Specifying layout rules for a grid is mandatory."
      );
    }
    this.currentLayoutRule = this.settings.layoutRules[0];
    const spots = this.generateCardSpots(this.currentLayoutRule);
    this.getRootElement().appendChild(spots.table);
    this.currentCardSpots = spots.cardSpots;
  }

  animationSpeedMultiplier = 1;

  setAnimationSpeedMultiplier(animationSpeedMultiplier: number) {
    this.animationSpeedMultiplier = animationSpeedMultiplier;
  }

  shouldIncludeOrder(order: MyOrderResponseOrder) {
    if (this.settings?.filter != null) {
      return testConditions(order, this.settings.filter);
    } else {
      return this.defaultFilter(order);
    }
  }

  private generateCardSpots(rule: GridLayoutRule) {
    const newCardSpots: CardSpot[] = [];
    const table = document.createElement("table");
    table.className = `order-grid-${this.orderGridId}-${rule.columns}x${rule.rows}-${rule.emphasizedRowCount}`;
    table.style.width = "100%";
    table.style.height = "100%";
    table.style.margin = "0";
    table.style.padding = "0";
    table.style.borderCollapse = "collapse";
    const colGroup = document.createElement("colgroup");
    for (var i = 0; i < rule.columns; i++) {
      const col = document.createElement("col");
      colGroup.appendChild(col);
      if (i < rule.columns - 1) {
        // If this is not the last column, add a spacing column
        const spacing = document.createElement("col");
        spacing.style.width = rule.gap.width + "px";
        colGroup.appendChild(spacing);
      }
    }
    table.appendChild(colGroup);
    const rows: HTMLTableRowElement[] = [];
    for (let i = 0; i < rule.rows; i++) {
      const row = document.createElement("tr") as HTMLTableRowElement;
      table.appendChild(row);
      if (i < rule.rows - 1) {
        // If this is not the last row, add a spacing row
        const spacing = document.createElement("tr");
        spacing.style.height = rule.gap.height + "px";
        table.appendChild(spacing);
      }
      rows.push(row);
    }
    let firstLocationIndicator: HTMLElement | null = null;
    for (let currentRow = 0; currentRow < rule.rows; currentRow++) {
      const row = rows[currentRow];
      for (
        let currentColumn = 0;
        currentColumn < rule.columns;
        currentColumn++
      ) {
        const cell = document.createElement("td") as HTMLTableCellElement;
        const locationIndicator = document.createElement("div");
        locationIndicator.style.height = "100%";
        locationIndicator.style.width = "100%";
        if (!firstLocationIndicator) {
          firstLocationIndicator = locationIndicator;
        }
        row.appendChild(cell);
        if (currentColumn < rule.columns - 1) {
          // We shouldn't forget adding an empty cell for the spacing
          const spacing = document.createElement("td");
          row.appendChild(spacing);
        }
        cell.appendChild(locationIndicator);
        if (!rule.emphasizedRowCount || currentRow >= rule.emphasizedRowCount) {
          newCardSpots.push({
            getLocationRect: () => locationIndicator.getBoundingClientRect(),
            card: undefined,
          });
        }
      }
      if (
        rule.emphasizedRowCount &&
        currentRow == rule.emphasizedRowCount - 1 &&
        firstLocationIndicator
      ) {
        newCardSpots.push({
          getLocationRect: () => {
            const firstSpotRect =
              firstLocationIndicator.getBoundingClientRect();
            return {
              height:
                firstSpotRect.height * rule.emphasizedRowCount +
                (rule.emphasizedRowCount - 1) * rule.gap.height,
              width: table.getBoundingClientRect().width,
              left: firstSpotRect.left,
              top: firstSpotRect.top,
            };
          },
          card: undefined,
        });
      }
    }
    return {
      cardSpots: newCardSpots,
      table: table,
    };
  }

  private root: HTMLElement | undefined;

  getRootElement: () => HTMLElement = () => {
    if (this.root === undefined) {
      const canvas = document.createElement("div");
      canvas.style.height = "100%";
      canvas.style.width = "100%";
      canvas.className = "order-grid order-grid-" + this.orderGridId;
      // const style = document.createElement("style");
      // style.innerHTML = this.settings.layoutRules
      //   .map(
      //     (rule) => `
      //   .__NOT_USED_order-grid-${this.orderGridId}-${rule.columns}x${rule.rows}-${rule.emphasizedRowCount} {
      //       border-collapse: collapse;
      //   }
      //   .__NOT_USED_order-grid-${this.orderGridId}-${rule.columns}x${rule.rows}-${rule.emphasizedRowCount} td {
      //       border: 0px solid transparent;
      //       background-clip: padding-box;
      //   }

      //   .__NOT_USED_order-grid-${this.orderGridId}-${rule.columns}x${rule.rows}-${rule.emphasizedRowCount} tr > td + td {
      //       border-left-width: ${rule.gap.width}px;
      //   }

      //   .__NOT_USED_order-grid-${this.orderGridId}-${rule.columns}x${rule.rows}-${rule.emphasizedRowCount} tr + tr > td {
      //       border-top-width: ${rule.gap.height}px;
      //   }
      // `
      //   )
      //   .reduce((acc, curr) => acc + curr, "");
      // canvas.appendChild(style);
      this.root = canvas;
    }
    return this.root;
  };

  static createStyleElement = () => {
    const style = document.createElement("style");
    style.innerHTML = ``;
    return style;
  };

  private getMaxCount(rule: GridLayoutRule): number {
    if (rule.emphasizedRowCount > 0) {
      return (
        rule.rows * rule.columns - rule.columns * rule.emphasizedRowCount + 1
      );
    } else {
      return rule.rows * rule.columns;
    }
  }

  private async tryMakeGridRightSizeFor(count: number): Promise<void> {
    const smallerRuleIndex =
      this.currentLayoutRule === undefined
        ? -1
        : (this.settings?.layoutRules?.indexOf(this.currentLayoutRule) || 0) -
          1;
    let currentRuleMinCount =
      this.currentLayoutRule === undefined
        ? -1
        : smallerRuleIndex < 0
        ? 0
        : this.getMaxCount(this.settings.layoutRules[smallerRuleIndex]) - 1;
    if (
      this.currentLayoutRule === undefined ||
      count < currentRuleMinCount ||
      count > this.getMaxCount(this.currentLayoutRule)
    ) {
      const newRule = this.settings.layoutRules.find(
        // Use the first rule which allow the desired count
        (r) => count <= this.getMaxCount(r)
      );
      if (newRule && newRule != this.currentLayoutRule) {
        this.currentLayoutRule = newRule;
        const spots = this.generateCardSpots(this.currentLayoutRule);
        const rootElement = this.getRootElement();
        for (let i = rootElement.childNodes.length - 1; i >= 0; i--) {
          if (/table/i.test(rootElement.childNodes[i].nodeName)) {
            rootElement.removeChild(rootElement.childNodes[i]);
          }
        }
        rootElement.appendChild(spots.table);
        const oldCardSpots = this.currentCardSpots;
        this.currentCardSpots = spots.cardSpots;
        await this.moveItemsToNewLayout(oldCardSpots, this.currentCardSpots);
      }
    }
  }

  private async moveItemsToNewLayout(
    oldCardSpots: CardSpot[],
    newCardSpots: CardSpot[]
  ) {
    await Promise.all(
      oldCardSpots
        .filter((s) => s.card)
        .map(async (s, i) => {
          const targetSpot = newCardSpots[i];
          await this.moveItem(
            s.card,
            s.getLocationRect(),
            targetSpot.getLocationRect()
          );
          targetSpot.card = s.card;
          s.card = undefined;
        })
    );
  }

  private async moveItem(
    item: OrderCard,
    from: CardSpotRect,
    to: CardSpotRect
  ): Promise<void> {
    await new Promise<void>((resolve) => {
      const targetRect = to;
      item.setPosition(targetRect);
      resolve();
      // setTimeout(
      //   () => resolve(),
      //   this.settings.animations.moveItem.endDelay /
      //     this.animationSpeedMultiplier
      // );
    });
  }

  private getItemCount() {
    return this.currentCardSpots.filter((s) => s.card).length;
  }

  getOrderCardContainingOrderOrNull(orderId: string) {
    const spot = this.currentCardSpots
      .filter((s) => s.card)
      .find((s) => s.card?.containsOrderId(orderId) || false);
    if (spot) {
      return spot.card;
    } else {
      return null;
    }
  }

  async addOrderCard(
    card: OrderCard,
    animateInFrom?: CardSpotRect | undefined,
    scaleCardSizeInAnimation?: boolean | undefined
  ): Promise<void> {
    card.setCardStyle(this.settings?.cardStyle);
    await this.tryMakeGridRightSizeFor(this.getItemCount() + 1);
    const emptySpace = this.currentCardSpots.find((s) => s.card === undefined);
    if (emptySpace === undefined) {
      //If there are no empty spaces, we want to add it as additional content in the last item
      const lastItem = this.currentCardSpots[this.currentCardSpots.length - 1];
      lastItem.card.addChildOrder(card.getFirstOrder(), "last");
      await new Promise<void>((resolve) =>
        setTimeout(
          () => resolve(),
          (this.settings?.animations?.animateIn?.endDelay || 0) /
            this.animationSpeedMultiplier
        )
      );
    } else {
      //If there are empty spaces, we want to add it in the first empty space

      if (animateInFrom === undefined) {
        // await animateIn(
        //   item,
        //   emptySpace.getBoundingClientRect(),
        //   this.settings?.animations?.animateIn,
        //   this.animationSpeedMultiplier
        // );
      } else {
        // await animateMove(
        //   item,
        //   animateInFrom,
        //   emptySpace.getBoundingClientRect(),
        //   this.settings.animations.animateIn,
        //   this.animationSpeedMultiplier,
        //   scaleCardSizeInAnimation === undefined
        //     ? true
        //     : scaleCardSizeInAnimation
        // );
      }
      emptySpace.card = card;
      const targetRect = emptySpace.getLocationRect();
      card.setPosition(targetRect);
      const cardRoot = card.getRootElement();
      this.getRootElement().appendChild(cardRoot);
      await new Promise<void>((resolve) =>
        setTimeout(
          () => resolve(),
          (this.settings?.animations?.animateIn?.endDelay || 0) /
            this.animationSpeedMultiplier
        )
      );
    }
  }

  async removeOrderIfContained(
    orderId: string,
    shouldAnimateOut: boolean
  ): Promise<{ orderCard: OrderCard; boundingClientRect: CardSpotRect }> {
    const spotWithOrder = this.currentCardSpots
      .filter((s) => s.card)
      .find((s) => s.card?.containsOrderId(orderId) || false);
    if (spotWithOrder) {
      let removedCard: OrderCard | undefined = undefined;
      if (shouldAnimateOut) {
        // TODO: Animate out
        await new Promise<void>((resolve) =>
          setTimeout(
            () => resolve(),
            (this.settings?.animations?.animateOut?.duration || 0) /
              this.animationSpeedMultiplier
          )
        );
      }
      if (spotWithOrder.card.getOrderCount() > 1) {
        const order = spotWithOrder.card.removeChildOrderIfContained(orderId);
        if (!order) {
          throw new Error(
            "For some reason we failed removing the card for order id " +
              orderId +
              ". This shouldn't happen, since previous checks should have already verified that this should work, so no automatic handling of this error has been implemented."
          );
        }
        removedCard = new OrderCard(order, this.getCardStyle());
        removedCard.setPosition(spotWithOrder.getLocationRect());
      } else {
        this.getRootElement().removeChild(spotWithOrder.card.getRootElement());
        removedCard = spotWithOrder.card;
        spotWithOrder.card = undefined;
      }
      if (shouldAnimateOut) {
        await new Promise<void>((resolve) =>
          setTimeout(
            () => resolve(),
            (this.settings?.animations?.animateOut?.endDelay || 0) /
              this.animationSpeedMultiplier
          )
        );
      }
      return {
        orderCard: removedCard,
        boundingClientRect: spotWithOrder.getLocationRect(),
      };
    } else {
      return null;
    }
  }

  async fillEmptySpaces(): Promise<void> {
    const movingPlans: Promise<void>[] = [];
    const emptySpaces: CardSpot[] = [];
    for (let i = 0; i < this.currentCardSpots.length; i++) {
      const currentSpot = this.currentCardSpots[i];
      if (currentSpot.card) {
        // If the current space has an item and there are empty spaces before it, move it
        let firstEmptySpace = emptySpaces.shift();
        if (firstEmptySpace) {
          // If there are many orders in the last slot, we should move those items out
          while (firstEmptySpace && currentSpot.card.getOrderCount() > 1) {
            const orderToMove = currentSpot.card.removeFirstOrder();
            const card = new OrderCard(orderToMove, this.getCardStyle());
            firstEmptySpace.card = card;
            const currentRect = currentSpot.getLocationRect();
            card.setPosition(currentRect);
            const targetRect = firstEmptySpace.getLocationRect();
            const cardRoot = card.getRootElement();
            this.getRootElement().appendChild(cardRoot);
            movingPlans.push(this.moveItem(card, currentRect, targetRect));

            firstEmptySpace = emptySpaces.shift();
          }

          // If there is only one piece of content in the item and there are still empty spaces available, move the item to the empty space
          const card = currentSpot.card;
          if (firstEmptySpace && card) {
            movingPlans.push(
              this.moveItem(
                card,
                currentSpot.getLocationRect(),
                firstEmptySpace.getLocationRect()
              )
            );
            firstEmptySpace.card = card;
            currentSpot.card = undefined;
            emptySpaces.push(currentSpot);
          }
        }
      } else {
        emptySpaces.push(currentSpot);
      }
    }
    await Promise.all(movingPlans);
    await this.tryMakeGridRightSizeFor(this.getItemCount());
  }

  getCardStyle() {
    return this.settings.cardStyle;
  }

  async makeSpaceEmpty(index: number): Promise<void> {
    // This is known to not support sending an index which is outside the spots available
    await this.tryMakeGridRightSizeFor(this.getItemCount() + 1);
    const movingPlans = [];
    const lastSpot = this.currentCardSpots[this.currentCardSpots.length - 1];
    for (let i = this.currentCardSpots.length - 2; i >= index; i--) {
      const currentSpace = this.currentCardSpots[i];
      if (!currentSpace.card) {
        // If there is no card on this space, nothing needs to be moved here, let's just continue
        continue;
      }
      if (i === this.currentCardSpots.length - 2 && lastSpot.card) {
        // If we are in the second to last spot and the last spot already has an entry, we should make this a child order of the last spot
        movingPlans.push(
          new Promise<void>(async (resolve, reject) => {
            try {
              const card = currentSpace.card;
              currentSpace.card = undefined;
              card.getRootElement().style.zIndex = "-1";
              await this.moveItem(
                card,
                currentSpace.getLocationRect(),
                lastSpot.getLocationRect()
              );
              lastSpot.card.addChildOrder(card.getFirstOrder(), "first");
              this.getRootElement().removeChild(card.getRootElement());
              resolve();
            } catch (e) {
              reject(e);
            }
          })
        );
      } else {
        // In all other cases we just move the item to the next spot.
        const targetSpace = this.currentCardSpots[i + 1];
        const card = currentSpace.card;
        movingPlans.push(
          this.moveItem(
            card,
            currentSpace.getLocationRect(),
            targetSpace.getLocationRect()
          )
        );
        targetSpace.card = card;
        currentSpace.card = undefined;
      }
    }
    await Promise.all(movingPlans);
  }
}
