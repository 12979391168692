import { logWarning } from "../services/logging";
import {
  Base64FontDefinition,
  FontSettings,
  FontType,
  UrlFontDefinition,
} from "../services/wosb-connection-contracts";

const getMediaTypeForFontType = (fontType: FontType): string => {
  switch (fontType) {
    case "woff2":
      return "font/woff2";
    case "woff":
      return "font/woff";
    default:
      logWarning(
        'Unsupported font type: "' +
          fontType +
          '". Going to pretent it is woff2'
      );
      return "font/woff2";
  }
};

export const createFontFaceStyleInnerHtml = (fonts: FontSettings): string => {
  if (!fonts) {
    return;
  }
  return Object.keys(fonts)
    .map((fontName) => {
      const fontDefinition = fonts[fontName];
      const base64Font = fontDefinition as Base64FontDefinition;
      if (base64Font.base64) {
        const mediaType = base64Font.fontType;
        return `@font-face {
                font-family: '${fontName}';
                src: url(data:${getMediaTypeForFontType(
                  base64Font.fontType
                )};charset=utf-8;base64,${base64Font.base64}) format('${
          base64Font.fontType
        }');
                font-weight: ${base64Font.fontWeight || "normal"};
                font-style: ${base64Font.fontStyle || "normal"};
            }`;
      } else {
        const urlFont = fontDefinition as UrlFontDefinition;
        return `@font-face {
                font-family: '${fontName}';
                src: url("${urlFont.url}") format('${urlFont.fontType}');
                font-weight: ${urlFont.fontWeight || "normal"};
                font-style: ${urlFont.fontStyle || "normal"};
            }`;
      }
    })
    .join("\n\n");
};
