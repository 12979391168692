import { ICondition } from "../../helpers/condition-helpers";
import {
  GridLayoutAnimationSetting,
  GridLayoutSettings,
} from "../grid-layout/grid-layout-settings";
import {
  AnimationSpeedThresholds,
  HorizontalSplitLayoutHeaderSetting,
} from "../horizontal-split-layout/horizontal-split-layout-settings";

export interface PortraitGridLayoutSettings {
  header: HorizontalSplitLayoutHeaderSetting | undefined;
  grid: GridLayoutSettings;
  filter: ICondition[] | undefined;
  animations: PortraitGridLayoutAnimationSettings;
  animationThresholds: AnimationSpeedThresholds[];
  rootGridTemplate: string | undefined;
}

export interface PortraitGridLayoutTextsSetting {
  header: string;
  ready: string;
  inProgress: string;
}

export interface PortraitGridLayoutAnimationSettings {
  emphasize: GridLayoutAnimationSetting;
}

export const getWithDefaultsForMissingSettings = (
  settings: PortraitGridLayoutSettings
): PortraitGridLayoutSettings => {
  const valueOrDefault = (
    val: number | null | undefined,
    _default: number
  ): number => {
    if (val === null || val === undefined) {
      return _default;
    } else {
      return val;
    }
  };
  const getAnimationSettingsOrDefault = (
    animationSettings: GridLayoutAnimationSetting | undefined | null,
    defaultDuration: number,
    defaultEndDelay: number
  ): GridLayoutAnimationSetting => {
    return {
      duration: valueOrDefault(animationSettings?.duration, defaultDuration),
      endDelay: valueOrDefault(animationSettings?.endDelay, defaultEndDelay),
    };
  };
  if (!settings) {
    settings = {} as any;
  }
  return {
    header: {
      text:
        settings?.header?.text === undefined ? "Status" : settings?.header?.text,
      align:
        settings?.header?.align === undefined
          ? "center"
          : settings?.header?.align,
      color:
        settings?.header?.color === undefined ? "black" : settings?.header?.color,
      fontFamily: settings?.header?.fontFamily,
      fontSize:
        settings?.header?.fontSize === undefined
          ? 120
          : settings?.header?.fontSize,
      fontStyle: settings?.header?.fontStyle,
      fontWeight: settings?.header?.fontWeight,
      justify:
        settings?.header?.justify === undefined
          ? "center"
          : settings?.header?.justify,
      padding:
        settings?.header?.padding === undefined
          ? "0px"
          : settings?.header?.padding,
    },
    animationThresholds:
      settings.animationThresholds?.length > 0
        ? settings.animationThresholds
        : [
            { queueCount: 60, speed: 8 },
            { queueCount: 40, speed: 4 },
            { queueCount: 20, speed: 2 },
            { queueCount: 10, speed: 1.5 },
          ],
    filter: settings.filter,
    grid: {
      filter: settings.grid?.filter,
      layoutRules:
        settings.grid?.layoutRules?.length > 0
          ? settings.grid.layoutRules
          : [
              {
                rows: 8,
                columns: 3,
                gap: {
                  height: 30,
                  width: 30,
                },
              },
              {
                rows: 10,
                columns: 4,
                gap: {
                  height: 25,
                  width: 25,
                },
              },
              {
                rows: 14,
                columns: 5,
                gap: {
                  height: 20,
                  width: 20,
                },
              },
            ],
      animations: {
        animateIn: getAnimationSettingsOrDefault(
          settings?.grid?.animations?.animateIn,
          1500,
          250
        ),
        animateOut: getAnimationSettingsOrDefault(
          settings?.grid?.animations?.animateOut,
          1000,
          0
        ),
        moveItem: getAnimationSettingsOrDefault(
          settings?.grid?.animations?.moveItem,
          750,
          250
        ),
      },
      cardStyle: {
        activityText: settings?.grid?.cardStyle?.activityText,
        background: settings?.grid?.cardStyle?.background,
        border:
          settings?.grid?.cardStyle?.border === undefined
            ? "solid 9px gray"
            : settings?.grid?.cardStyle?.border,
        borderRadius: settings?.grid?.cardStyle?.borderRadius,
        color: settings?.grid?.cardStyle?.color,
        fontFamily: settings?.grid?.cardStyle?.fontFamily,
        fontSizeMultiplier:
          settings?.grid?.cardStyle?.fontSizeMultiplier === undefined
            ? 1
            : settings?.grid?.cardStyle?.fontSizeMultiplier,
        fontStyle: settings?.grid?.cardStyle?.fontStyle,
        fontWeight: settings?.grid?.cardStyle?.fontWeight,
        conditional:
          settings?.grid?.cardStyle?.conditional === undefined
            ? [
                {
                  conditions: [
                    {
                      type: "operator",
                      operator: "equals",
                      property: "status",
                      value: "inProgress",
                    },
                  ],
                  style: { activityText: "preparing" },
                },
                {
                  conditions: [
                    {
                      type: "operator",
                      operator: "equals",
                      property: "status",
                      value: "done",
                    },
                  ],
                  style: { activityText: "ready" },
                },
              ]
            : settings?.grid?.cardStyle?.conditional,
      },
    },
    animations: {
      emphasize: getAnimationSettingsOrDefault(
        settings?.animations?.emphasize,
        1500,
        2500
      ),
    },
    rootGridTemplate: settings?.rootGridTemplate,
  };
};

// export const getDefaultSettings = (): PortraitGridLayoutSettings => {
//   return {
//     texts: {
//       header: "Order Status",
//       ready: "ready",
//       inProgress: "preparing",
//     },
//     grid: {
//       layoutRules: [
//         {
//           rows: 8,
//           columns: 3,
//           gap: {
//             height: 30,
//             width: 30,
//           },
//         },
//         {
//           rows: 10,
//           columns: 4,
//           gap: {
//             height: 25,
//             width: 25,
//           },
//         },
//         {
//           rows: 14,
//           columns: 5,
//           gap: {
//             height: 20,
//             width: 20,
//           },
//         },
//       ],
//       animations: {
//         animateIn: {
//           duration: 1500,
//           endDelay: 250,
//         },
//         animateOut: {
//           duration: 1000,
//           endDelay: 0,
//         },
//         moveItem: {
//           duration: 750,
//           endDelay: 250,
//         },
//       },
//       cardStyle: {
//         conditional: [
//           {
//             conditions: [
//               {
//                 type: "operator",
//                 operator: "equals",
//                 property: "name",
//                 value: "3023",
//               },
//             ],
//             style: {
//               borderRadius: "50%",
//             },
//           },
//         ],
//       },
//     },
//     animations: {
//       emphasize: {
//         duration: 1500,
//         endDelay: 2500,
//       },
//     },
//     animationThresholds: [
//       { queueCount: 60, speed: 8 },
//       { queueCount: 40, speed: 4 },
//       { queueCount: 20, speed: 2 },
//       { queueCount: 10, speed: 1.5 },
//     ],
//   };
// };
