import { getValueFromPropertyPath } from "./condition-helpers";

const replaceRegex = /({{[^}]+}})/g;

export function getValueWithAppliedTemplate(template: string, viewModel: any) {
  if (template) {
    const result = template.replace(replaceRegex, (capture) =>
      getValueFromPropertyPath(
        capture.substring(2, capture.length - 2)?.trim(),
        viewModel
      )
    );
    return result;
  } else {
    return null;
  }
}
