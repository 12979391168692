// This file exists to allow webpack to ensure all polyfills in polyfill1 are run before the polyfills in polyfill2, because otherwise it sometimes messes up the order.

// Polyfilling Headers
import { Headers } from "headers-polyfill";
if (!window.Headers) {
  window.Headers = Headers;
}

//Polyfilling fetch
import 'whatwg-fetch';

export const noOpToExportAMethodWhichCanBeCalledBeforeOtherImportsToEnsureWebpackMaintainsCorrectImportOrderForPolyfill2 =
  () => {};
