import { createOrderChangeQueue } from "../../full/src/services/order-change-queue";
import {
  addLoggingContextState,
  logDebug,
  logError,
  logInfo,
  logWarning,
} from "../../full/src/services/logging";
import { connection } from "../../full/src/services/wosb-connection";
import { SplashScreen } from "./components/splash-screen/splash-screen";
import {
  MyOrderResponseOrderStatus,
  ThemeSettings,
} from "../../full/src/services/wosb-connection-contracts";
import {
  getWithDefaultsForMissingSettings as getHorizontalSplitSettingsWithDefaultsForMissingSettings,
  HorizontalSplitLayoutSettings,
} from "../../full/src/components/horizontal-split-layout/horizontal-split-layout-settings";
import {
  getWithDefaultsForMissingSettings as getPortraitGridSettingsWithDefaultsForMissingSettings,
  PortraitGridLayoutSettings,
} from "../../full/src/components/portrait-grid-layout/portrait-grid-layout-settings";
import {
  IComponent,
  OrderStatusLayout,
} from "./components/component-interfaces";
import { HorizontalSplitLayout } from "./components/horizontal-split-layout/horizontal-split-layout";
import { PortraitGridLayout } from "./components/portrait-grid-layout/portrait-grid-layout";
import { createFontFaceStyleInnerHtml } from "../../full/src/helpers/font-helpers";
import { headersToList } from "headers-polyfill";
import { clearEnrollmentInfo } from "../../full/src/services/enrollment-service";

const rootElement = document.getElementById("root");

const setRootContent = (
  content: HTMLElement,
  background?: string | undefined
) => {
  if (background) {
    document.body.style.background = background;
  } else {
    document.body.style.background = null;
  }
  rootElement.innerHTML = "";
  rootElement.appendChild(content);
};

const delay = (time: number) =>
  new Promise<void>((resolve) => {
    setTimeout(() => resolve(), time);
  });

const createElementWithInnerText = (elementName: string, innerText: string) => {
  const element = document.createElement(elementName);
  element.innerText = innerText;
  return element;
};

const w: any = window;
const startPollingForFrontendUpdates = () => {
  let currentVersion: string | undefined = w.wosbSettings?.version;
  function ensureLatestVersionIsLoaded() {
    if (currentVersion) {
      window
        .fetch("/build.json")
        .then(async (resp) => {
          if (resp.status >= 200 && resp.status < 300) {
            const res = await resp.json();
            if (res?.version) {
              if (currentVersion !== res.version) {
                logInfo(
                  `New application version has been deployed. Will reload application.`,
                  { LoadedVersion: currentVersion, ServerVersion: res.version }
                );
                window.location.reload();
              }
            } else {
              logWarning(
                "The content of build.json was either null or did not contain a version number"
              );
            }
          } else {
            logWarning(
              "Received response code " +
                resp.status +
                " when retrieving build.json"
            );
          }
        })
        .catch((e) => logWarning("Failed retrieving build.json", e));
    }
  }
  const checkForNewVersionIntervalMinutes = 15;
  setInterval(
    ensureLatestVersionIsLoaded,
    checkForNewVersionIntervalMinutes * 60 * 1000
  );
};

const createPreAndPostText: (
  preText: string,
  element: HTMLElement,
  postText: string
) => HTMLElement = (
  preText: string,
  element: HTMLElement,
  postText: string
) => {
  const paragraph = document.createElement("p");
  paragraph.appendChild(createElementWithInnerText("span", preText));
  paragraph.appendChild(element);
  paragraph.appendChild(createElementWithInnerText("span", postText));
  return paragraph;
};

const createTimerElement: (
  numberOfSeconds: number,
  callback: () => void
) => HTMLElement = (numberOfSeconds: number, callback: () => void) => {
  const timeLeftElement = document.createElement("span");
  timeLeftElement.innerText = numberOfSeconds.toString();
  let timeLeft = numberOfSeconds;
  let oneSecondPassed: () => void | null = null;
  oneSecondPassed = () => {
    timeLeft = timeLeft - 1;
    if (timeLeft <= 0) {
      timeLeftElement.innerText = "0";
      callback();
    } else {
      timeLeftElement.innerText = timeLeft.toString();
      setTimeout(oneSecondPassed, 1000);
    }
  };
  setTimeout(oneSecondPassed);
  return timeLeftElement;
};

const createLayout: (theme: ThemeSettings) => {
  layout: OrderStatusLayout;
  fontStyle: HTMLElement;
} = (theme: ThemeSettings) => {
  const fontFaces = document.createElement("style");
  fontFaces.innerHTML = createFontFaceStyleInnerHtml(theme.fonts);
  switch (theme?.layoutType) {
    case "horizontal-split":
      const hsettings =
        getHorizontalSplitSettingsWithDefaultsForMissingSettings(
          theme.layoutSettings as HorizontalSplitLayoutSettings
        );
      return {
        layout: new HorizontalSplitLayout(hsettings),
        fontStyle: fontFaces,
      };
    case "portrait-grid":
      const psettings = getPortraitGridSettingsWithDefaultsForMissingSettings(
        theme.layoutSettings as PortraitGridLayoutSettings
      );
      return {
        layout: new PortraitGridLayout(psettings),
        fontStyle: fontFaces,
      };
    default:
      const error: any = new Error(
        "Could not create layout with type " + theme.layoutType
      );
      error.errorType = "unsupported-layout-type";
      error.data = {
        layoutType: theme?.layoutType,
      };
      throw error;
  }
};

export const startApp = async () => {
  addLoggingContextState({
    WosbMode: 'compatibility'
  });
  logDebug("Starting app");

  const splashScreen = new SplashScreen([
    createElementWithInnerText("h1", "Order Status Board"),
    createElementWithInnerText("p", "Powered by Future Ordering"),
  ]);
  setRootContent(splashScreen.getRootElement());

  const minimumSplashscreenDelay = delay(3000);

  startPollingForFrontendUpdates();
  try {
    connection.onConnectionLost = () => {
      logWarning("Was unable to retrieve a new access token.");
      setRootContent(
        new SplashScreen([
          createElementWithInnerText("h1", "Connection Lost"),
          createPreAndPostText(
            "Trying to reconnect in ",
            createTimerElement(30, () => window.location.reload()),
            " seconds"
          ),
        ]).getRootElement()
      );
    };
    connection.onDeviceSettingsChanged = () => {
      logInfo("Received new settings, reloading application");
      window.location.reload();
    };
    connection.onDisconnected = () => {
      logWarning("Was unable to retrieve a new access token.");
      setRootContent(
        new SplashScreen([
          createElementWithInnerText("h1", "Device Disconnected"),
          createPreAndPostText(
            "Trying to reconnect in ",
            createTimerElement(30, () => window.location.reload()),
            " seconds"
          ),
        ]).getRootElement()
      );
    };
    await Promise.all([connection.connect(), minimumSplashscreenDelay]);
  } catch (err) {
    if (err.errorType === "invalid-credentials") {
      clearEnrollmentInfo();
      logError(err);
      setRootContent(
        new SplashScreen([
          createElementWithInnerText("h1", "Enrollment expired"),
          createElementWithInnerText("p", "Please enroll device"),
        ]).getRootElement()
      );
    } else if (err.errorType === "invalid-clock") {
      logError(err);
      setRootContent(
        new SplashScreen([
          createElementWithInnerText("h1", "Invalid Device Clock Setting"),
          createElementWithInnerText(
            "p",
            "The clock on the device seems to be set wrong."
          ),
          createElementWithInnerText("p", "Device Clock: " + err.localTime),
          createElementWithInnerText("p", "Server Clock: " + err.serverTime),
        ]).getRootElement()
      );
    } else {
      logError(err);
      const removeEnrollmentInfoLink = document.createElement("a");
      removeEnrollmentInfoLink.innerText = "Remove enrollment";
      removeEnrollmentInfoLink.addEventListener("click", () => {
        clearEnrollmentInfo();
        return true;
      });
      removeEnrollmentInfoLink.href = "/" + location.search;
      setRootContent(
        new SplashScreen([
          createElementWithInnerText("h1", "Connection failed"),
          createPreAndPostText(
            "Trying to reconnect in ",
            createTimerElement(2 * 60, () => window.location.reload()),
            " seconds"
          ),
          removeEnrollmentInfoLink,
        ]).getRootElement()
      );
    }
    return;
  }
  try {
    const themeSettings = await connection.getDeviceTheme();
    const layoutAndFontFaces = createLayout(themeSettings);
    document.head.appendChild(layoutAndFontFaces.fontStyle);
    setRootContent(
      layoutAndFontFaces.layout.getRootElement(),
      themeSettings.background
    );
    const queue = await createOrderChangeQueue(connection);
    layoutAndFontFaces.layout.applyQueue(queue);
    const w: any = window;
    w.pushOrder = (
      orderName: string,
      status: MyOrderResponseOrderStatus,
      orderTypeId?: string | undefined,
      salesChannel?: any | undefined,
      collectionDetails?: any | undefined
    ) => {
      queue.enqueue({
        type: "changed",
        order: {
          name: orderName,
          orderId: orderName,
          status: status,
          salesChannel: salesChannel,
          orderTypeId: orderTypeId,
          collectionDetails: collectionDetails,
        },
      });
    };
    logDebug("App started successfully");
  } catch (err) {
    logError(err);
    if (err.errorType === "unsupported-layout-type") {
      setRootContent(
        new SplashScreen([
          createElementWithInnerText("h1", "Invalid Theme Setting"),
          createElementWithInnerText(
            "p",
            `Desired Theme Layout ('${err.data.layoutType}') not supported by the Order Status Board lite.`
          ),
        ]).getRootElement()
      );
    } else {
      setRootContent(
        new SplashScreen([
          createElementWithInnerText("h1", "Failed loading theme"),
          createPreAndPostText(
            "Trying to reconnect in ",
            createTimerElement(2 * 60, () => window.location.reload()),
            " seconds"
          ),
        ]).getRootElement()
      );
    }
  }
};
