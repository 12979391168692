import { logWarning } from "../services/logging";

export interface ICondition {
  type: "operator";
  operator: "equals" | "notequals" | "matches";
  property: string;
  value: string;
}

export function testConditions(data: any, conditions: ICondition[]) {
  if (conditions == null) {
    return true;
  }
  for (let i = 0; i < conditions.length; i++) {
    if (!testCondition(data, conditions[i])) {
      return false;
    }
  }
  return true;
}

export function getFailingConditions(data: any, conditions: ICondition[]) {
  if (conditions == null) {
    return [];
  }
  return conditions.filter((c) => !testCondition(data, c));
}

export function getValueFromPropertyPath(
  propertyPath: string,
  data: any
): any | null {
  if (!propertyPath) {
    return null;
  }
  const propertyPathSplit = propertyPath
    // first split by any parameters that should be allowed to contain a period in the property name
    .split("['")
    .flatMap((s, i) =>
      // any entry that is not preceeded by [' is not allowed to contain a period in the property name
      i === 0
        ? s.split(".")
        : // now split the entries that start with a [' on the end syntax of ']
          s.split(/'\]\.?/).flatMap((s, i) =>
            // any entry after a [' should have a single property name until the end '], even if it contains a period
            i === 0
              ? s
              : // but any entry after the end '] should be split by the period character
                s.split(".")
          )
    )
    // then just make sure we don't have any empty entry in the end
    .filter((s) => s);
  let propertyValue: any | null = null;
  try {
    propertyValue = propertyPathSplit.reduce((c: any, p) => c[p], data);
    return propertyValue ?? null;
  } catch {
    return null;
  }
}

const warnedAboutOperators: string[] = [];
const warnedAboutConditions: string[] = [];
const warnedAboutRegexes: string[] = [];
const regexes: Record<string, RegExp> = {};
export function testCondition(data: any, condition: ICondition) {
  switch (condition.type) {
    case "operator":
      if (!condition.property) {
        return false;
      }
      const propertyValue = getValueFromPropertyPath(condition.property, data);
      switch (condition.operator) {
        case "equals":
          return (
            propertyValue == condition.value ||
            propertyValue?.toString() == condition.value
          );
        case "notequals":
          return !(
            propertyValue == condition.value ||
            propertyValue?.toString() == condition.value
          );
        case "matches":
          let existingRegex = regexes[condition.value];
          if (!existingRegex) {
            // If we have warned about the regex before, we can just return false and not try to parse it again
            if (warnedAboutRegexes.indexOf(condition.value) !== -1) {
              return false;
            }
            // try to parse the regex, if it fails, warn and return false
            try {
              // We might want to consider supporting regex options, but that is not needed right now.
              existingRegex = new RegExp(condition.value);
              regexes[condition.value] = existingRegex;
            } catch {
              logWarning("Failed parsing regex", { regex: condition.value });
              warnedAboutRegexes.push(condition.value);
              return false;
            }
          }
          return existingRegex.test(propertyValue);
        default:
          if (warnedAboutOperators.indexOf(condition.operator) === -1) {
            logWarning("Invalid operator: " + condition.operator);
            warnedAboutOperators.push(condition.operator);
          }
          return false;
      }
      break;
    default:
      if (warnedAboutConditions.indexOf(condition.type) === -1) {
        logWarning("Invalid condition type: " + condition.type);
        warnedAboutConditions.push(condition.type);
      }
      return false;
  }
}
