import "core-js/actual/symbol";
import "core-js/actual/object/set-prototype-of";
import "core-js/actual/object/assign";
import "core-js/actual/map";
import "core-js/actual/weak-map";
import "core-js/actual/array";
import "core-js/actual/string";

// Polyfilling Symbol
// if (!window.Symbol){
//     require("core-js/actual/symbol");
// }
// Polyfilling setPrototypeOf
//import "./setPrototypeOfPolyfill";
// if (!Object.setPrototypeOf){
//     require("core-js/es/object/set-prototype-of");
// }
// Polyfilling URLSearchParams
import "url-search-params-polyfill";
// Polyfilling Promise
import * as es6Promise from "es6-promise";

if (!window.Promise) {
  es6Promise.polyfill();
}

// Polyfilling WeakMap
//import "weakmap-polyfill";
// Polyfilling AbortController for Chrome around version 56, which seems to be used by some Samsung TVs; see https://github.com/dotnet/aspnetcore/issues/30458
import "abort-controller/polyfill";

export const noOpToExportAMethodWhichCanBeCalledBeforeOtherImportsToEnsureWebpackMaintainsCorrectImportOrderForPolyfill1 =
  () => {};
